import React, { useState, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa"
import { useParams } from 'react-router-dom';
import * as Constant from "../Constant";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { HiChevronDoubleRight } from 'react-icons/hi'
import { FaGraduationCap } from 'react-icons/fa'
import LoadingOverlay from 'react-loading-overlay';

function SubjectDetails() {
    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const [active, setActive] = useState(false)
    const [exploreMore, setExploreMore] = useState(false)
    const [subjectList, setSubjectList] = useState([])
    const [subLevelList, setSubLevelList] = useState([])
    const [levelList, setLevelList] = useState([])
    const [subjectid, setSubjectId] = useState()
    const [levelid, setLevelId] = useState()
    const [sublevelid, setSubLevelId] = useState()
    const [state, setState] = useState({
        TopicList: [],
        TopicDetails: [],
        subjectName: '',
        levelName: '',

    })
    const [approve, setApprove] = useState()
    const [aleveId, setAleveId] = useState()


    const getTopics = async () => {

        await fetch(Constant.getTopics + '/' + id, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setLoading(false)
                    setState({
                        ...state,
                        TopicList: data.data,
                        subjectName: data.data[0].subject,
                        levelName: data.data[0].level,
                    })
                } else {
                    console.log('Topic not found')
                }
            })


    }

    const onSubmit = () => {
        setExploreMore(true)
    }

    const submitMoreSubject = async () => {
        setExploreMore(false)
        setActive(true)

        var userId = localStorage.getItem('userId')

        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("subject_id", subjectid);
        formData.append("level_id", levelid);
        formData.append("sub_level_id", sublevelid);


        await fetch(Constant.moreUserSubject, {
            method: 'POST',
            body: formData
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setActive(false)
                    window.location.href = Constant.BaseUrl + 'student-dashboard'
                } else {
                    setActive(false)
                }
            })
    }

    const showSubject = () => {
        window.location.href = Constant.BaseUrl + state.subjectName.toLowerCase()
    }

    useEffect(() => {
        getTopics();
        moreSubject()
        getSubject();
    }, [])

    const showsubDetails = (cid) => {
        const url = Constant.BaseUrl + 'topic-details/' + cid + '/' + id;
        window.open(url, '_blank');
    }

    const onPayment = () => {
        window.location.href = Constant.BaseUrl + 'membership'
    }

    const handleChangeSubject = (e) => {
        setSubjectId(e.target.value)
        getLevel(e.target.value)
    }

    const handleChangeLevel = (e) => {
        setLevelId(e.target.value)
        getSubLevel(e.target.value)
    }

    const handleChangeSubLevel = (e) => {
        setSubLevelId(e.target.value)
    }

    const moreSubject = async () => {

        var userId = localStorage.getItem('userId')
        var subjectId = localStorage.getItem('subjectId')

        await fetch(Constant.getUserMoreSub + '/' + userId + '/' + subjectId + '/' + id, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setApprove(data.data[0].is_approved)
                    setAleveId(data.data[0].sub_level_id)
                }
            })
    }

    const getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    console.log(data.data)
                    setSubjectList(data.data)
                } else {
                    console.log('subject not available')
                }
            })
    }

    const getLevel = async (value) => {

        await fetch(Constant.getSubjectLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setLevelList(data.data)
                }
            })
    }

    const getSubLevel = async (value) => {

        await fetch(Constant.getSubLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    setSubLevelList(data.data)
                }
            })
    }


    return (
        <LoadingOverlay
            active={active}
            spinner
            fadeSpeed={10}
            text='Submiting...'
        >
            <>
                {
                    loading === true ? <div className="text-center mt-4 mb-4"> <Skeleton height={50} width={200} count={1} /></div> :
                        <>
                            {
                                state.subjectName !== '' ? <div className="text-center mt-4 mb-4"><div style={{ display: 'flex', justifyContent: 'center' }}><h1 style={{ cursor: 'pointer' }} onClick={() => showSubject()}>{state.subjectName} &nbsp;&nbsp;&nbsp;</h1><h1><FaChevronRight style={{ marginLeft: '-30px' }} /> {state.levelName}</h1></div></div > : <div className="text-center mt-4 mb-4"><h3>List Not Found</h3></div >
                            }
                        </>

                }
                <div className="container">
                    {
                        loading === true ?
                            <div className="row mb-4">
                                <div className="col-md-4 padding">
                                    <div class="details-card-header" >
                                        <Skeleton height={20} width={300} count={1} />
                                    </div>
                                    <div className="details-card-body">
                                        <Skeleton height={20} width={300} count={10} />
                                    </div>
                                </div>
                                <div className="col-md-4 padding">
                                    <div class="details-card-header" >
                                        <Skeleton height={20} width={300} count={1} />
                                    </div>
                                    <div className="details-card-body">
                                        <Skeleton height={20} width={300} count={10} />
                                    </div>
                                </div>
                                <div className="col-md-4 padding">
                                    <div class="details-card-header" >
                                        <Skeleton height={20} width={300} count={1} />
                                    </div>
                                    <div className="details-card-body">
                                        <Skeleton height={20} width={300} count={10} />
                                    </div>
                                </div>
                            </div> :

                            <>
                                <div className="row mb-4">
                                    {
                                        state.TopicList.map((item, i) => (
                                            <div className="col-md-4 padding" key={i}>
                                                <div class="details-card-header" >
                                                    <h3 class="card-title" style={{ color: '#fff', fontSize: 21 }}>
                                                        <FaGraduationCap style={{ fontSize: 30 }} /> {item.name}
                                                    </h3>
                                                </div>
                                                {
                                                    item.topicdetails.length !== 0 ? <div className="details-card-body">
                                                        {
                                                            item.topicdetails.map((value, i) => (
                                                                <ul key={i}>
                                                                    <li className="formatdetails responsive" style={{ cursor: 'pointer', display: 'flex' }} onClick={() => showsubDetails(value.id)}>
                                                                        <HiChevronDoubleRight style={{ color: '#9E1F6D', marginTop: 5 }} /><p className="colorBlack" style={{ fontSize: 12, fontWeight: 600, textAlign: 'left' }}>&nbsp;&nbsp;{value.title}</p>
                                                                    </li>
                                                                </ul>
                                                            ))
                                                        }
                                                    </div> :
                                                        <div className="details-card-body">
                                                            <ul>
                                                                <li className="format">
                                                                    <p className="colorBlack">Not Found</p>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                }
                                            </div>
                                        ))
                                    }

                                </div>
                                <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-4 pb-5 text-center">
                                        <button
                                            style={{ width: '60%' }}
                                            type="submit"
                                            name="submit"
                                            onClick={onSubmit}
                                            class="btnRegister"
                                        >

                                            Request for more courses

                                        </button>
                                    </div>
                                    <div className="col-md-4 pb-5 text-center">
                                        <button
                                            style={{ width: '60%' }}
                                            type="submit"
                                            name="submit"
                                            onClick={onPayment}
                                            class="btnRegister"
                                        >
                                            Enrolled subject
                                        </button>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>
                                {
                                    exploreMore === true ?
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <h5>Subject</h5>
                                                <select name="subjectid" id="subjectid" onClick={handleChangeSubject} className="form-control country_dropdown mt-1">
                                                    <option value="">Select Subject</option>
                                                    {
                                                        subjectList.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.title}</option>
                                                        ))
                                                    }

                                                </select>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <h5>Level</h5>
                                                {
                                                    levelList.length === 0 ? <select className="form-control mt-1"><option>Level Not Found</option></select> :
                                                        <select name="levelid" onChange={handleChangeLevel} autoComplete="on" className="form-control mt-1">
                                                            <option value="">Select Level</option>
                                                            {
                                                                levelList.map((item, i) => (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                }
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <h5>Sub Level</h5>
                                                <select name="sublevelid" id="sublevelid" onChange={handleChangeSubLevel} className="form-control country_dropdown">
                                                    <option value="">Select Sub Level</option>
                                                    {
                                                        subLevelList.map((item, i) => (
                                                            <>
                                                                {
                                                                    aleveId === item.id ? '' : <option key={i} value={item.id}>{item.name}</option>
                                                                }
                                                            </>

                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-md-3 form-group" style={{ marginTop: 20 }}>
                                                <button
                                                    style={{ width: 200 }}
                                                    type="submit"
                                                    name="submit"
                                                    onClick={submitMoreSubject}
                                                    class="btnRegister"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div> : ''
                                }
                            </>
                    }
                </div>
            </>
        </LoadingOverlay>
    )

}

export default SubjectDetails