import React from "react";

import * as Constant from '../Constant'
import reviewgoogle from '../../assets/img/newhomepage/googlereview1.png'
import punjabi_banner from '../../assets/img/subjects/contact_img.png'
import trust_pilot from '../../assets/img/trust_pilot.png'
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';

import gurumukhi from '../../assets/img/subjects/gurumukhi.png'
import practical_lang from '../../assets/img/subjects/practical_lang.png'
import veg from '../../assets/img/subjects/veg.png'
import comman_word from '../../assets/img/subjects/comman_word.png'
import matrawas from '../../assets/img/subjects/matrawas.png'
import date from '../../assets/img/subjects/date.png'
import animal from '../../assets/img/subjects/animal.png'
import greting_pun from '../../assets/img/subjects/greting_pun.png'
import benifit_1 from '../../assets/img/subjects/benifit_1.png'
import benifit_2 from '../../assets/img/subjects/benifit_2.png'

class Punjabi extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoadingProfile: false,
            role_id: '',


            nameError: null,
            emailError: null,
            phoneError: null,
            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            captchError: null,
            num1: Math.floor(Math.random() * 10) + 1,
            num2: Math.floor(Math.random() * 10) + 1,
            answer: '',
            isCorret: '',

            SubjectList: [],
            subject_id: '',
            level_id: '',
            LevelList: [],
            level_idError: null,
            subject_idError: null,
            country_idError: null,
            country_id: '',
            paperList: []
        }

        this.recaptchaRef = React.createRef();
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
    }

    componentDidMount = () => {
        this.getProfile();
        this.getSubject();
        this.getPapers();
    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }

    getPapers = async () => {
        this.setState({ isLoading: true })

        var subjectId = localStorage.getItem('subjectId')

        await fetch(Constant.getPapers + '/' + subjectId, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                this.setState({ paperList: data.data, isLoading: false })
            })
    }


    showPaper = (id, totalQuestion) => {
        if (totalQuestion === 0) {
            const url = Constant.BaseUrl + 'contact-us'
            window.location.href = url;
        } else {
            const url = Constant.BaseUrl + 'exam_papers/1/' + id;
            // window.open(url, '_blank');
            window.location.href = url;
        }
    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let level_idError = '';
        let subject_idError = '';
        let country_idError = '';


        if (!this.state.name) {
            nameError = "Please Enter Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "The Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (this.state.level_id.length == 0) {
            level_idError = "Please select level"
        }

        if (this.state.subject_id.length == 0) {
            subject_idError = "Please select subject"
        }

        if (this.state.country_id.length == 0) {
            country_idError = "Please select country"
        }

        if (!this.state.phone) {
            phoneError = "Please Enter Contact Number"
        } else {
            if (this.state.phone.length <= 7) {
                phoneError = "Phone number cannot be less than eight digit"
            }
        }

        if (level_idError || subject_idError || nameError || emailError || phoneError || country_idError) {
            this.setState({
                level_idError,
                subject_idError,
                nameError,
                emailError,
                phoneError,
                country_idError
            });

            return false;
        }

        return true;
    }

    onEnrollDemo = async () => {
        this.setState({ isLoading: true })

        const recaptchaValue = await this.recaptchaRef.current.executeAsync();

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("country_id", this.state.country_id);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);
        formData.append("recaptchaToken", recaptchaValue);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        userInput: '',
                        country_id: '',
                        subject_id: '',
                        level_id: ''

                    })

                    toast.success('Your request forwarded successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };

    handleChangeSubject = (e) => {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel = (e) => {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
    }

    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    getLevel = async (value) => {

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoading: false
                    })
                }
            })
    }

    getProfile = async () => {
        this.setState({ isLoadingProfile: true })

        const userId = localStorage.getItem('userId');

        await fetch(Constant.getUserProfile + '/' + userId, {
            method: "GET"
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        role_id: data.data.role_id,
                    })
                } else {
                    console.log('Not Found')
                }
            }
            )
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
                    <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 pt-5">
                        <p className=" mt-5 punjabi_font" >Your Gateway to<br></br> Mastering Punjabi</p>

                        <p className="colorBlack mt-4 pb-3 banner_sub_text" style={{ color: '#000', fontWeight: 600, fontSize: 18 }}>ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ! (Sat Sri Akal)! Today,we’ll be starting with a<br></br> fundamental aspect of any language and culture - greetings and politeness.</p>

                        <div className="mt-4 ">
                            <img alt="maths_banner" src={punjabi_banner} className="banner_radius" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                        <div className="learning-form " style={{ marginTop: 100 }} >
                            <h2>Book Free Lesson Class</h2>
                            <p>Learn From Best Online Tutors</p>
                            <div className="slider-form-outer">
                                <div className="form-group">
                                    <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                                    {!!this.state.nameError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.nameError}
                                        </text>
                                    )}

                                    <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" minLength="10" maxLength="10" />
                                    {!!this.state.phoneError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.phoneError}
                                        </text>
                                    )}

                                    <input name="email" value={this.state.email} onChange={(event) =>
                                        this.setState({ email: event.target.value })
                                    } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                                    {!!this.state.emailError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.emailError}
                                        </text>
                                    )}

                                    <select name="subject_id" id="subject_id" onChange={this.handleChangeSubject} value={this.state.subject_id} className="form-control country_dropdown mt-1">
                                        <option value="">Select Subject</option>
                                        {
                                            this.state.SubjectList.map((item, i) => (
                                                <option key={i} value={item.id}>{item.title}</option>
                                            ))
                                        }

                                    </select>
                                    {!!this.state.subject_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.subject_idError}
                                        </text>
                                    )}

                                    {
                                        this.state.LevelList.length === 0 ? <select className="form-control mt-1"><option>Level Not Found</option></select> :
                                            <select name="level_id" autoComplete="on" value={this.state.level_id} onChange={this.handleChangeLevel} className="form-control mt-1">
                                                <option value="">Select Level</option>
                                                {
                                                    this.state.LevelList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    ))
                                                }

                                            </select>
                                    }
                                    {!!this.state.level_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.level_idError}
                                        </text>
                                    )}

                                    <select name="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control country_dropdown mt-1">
                                        <option value="">Select Country</option>
                                        <option value="13" >Australia</option>
                                        <option value="99" >India</option>
                                        <option value="225" >United Kingdom</option>
                                        <option value="226" >United States</option>
                                    </select>
                                    {!!this.state.country_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.country_idError}
                                        </text>
                                    )}


                                    <div className="d-flex align-items-center ">
                                        <ReCAPTCHA
                                            sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                            size="invisible"
                                            ref={this.recaptchaRef}
                                        />
                                    </div>
                                    <div className="col-sm-6 ml-auto" >
                                        <button
                                            type="submit"
                                            name="submit"
                                            onClick={() => this.onSubmit()}
                                            className="btnRegister enrolldemo"
                                        >

                                            {this.state.isLoading ? (
                                                <ClipLoader
                                                    type="TailSpin"
                                                    color="#ffffff"
                                                    height="20"
                                                    width="20"
                                                />
                                            ) : (
                                                "Book Demo Lessons"
                                            )}

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
                </div>

                <ToastContainer />

                <div className="mt-5 bg-black" style={{ border: 'none' }}>
                    <div className="row">
                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11">
                                    <div className="reviwesfont1">5000+</div>
                                    <div className="reviwesfont2">Student Enrolled</div>
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11 text-center">
                                    <img src={trust_pilot} alt="star" />
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 text-center" style={{ padding: 50 }}>
                            <img src={reviewgoogle} alt='reviews' />
                        </div>

                    </div>
                </div>

                <p className="text-center mt-5 head_font" >Get One to One Online Punjabi Tutor</p>
                <div className="container">
                    <div className="row ">
                        <p className="colorBlack mt-4" style={{ fontWeight: 500, fontSize: 18, textAlign: 'justify' }}>Greetings are an essential part of Punjabi culture. They reflect respect and warmth, and it’s important to know how to greet others in different situations. Greetings set the tone for interactions and show your courtesy.</p>
                        <p className="colorBlack mt-4" style={{ fontWeight: 500, fontSize: 18, textAlign: 'justify' }}>Let’s begin by learning some common Punjabi greetings that you can use in various contexts. These greetings are polite and convey good wishes.
                            <br></br><br></br>    1.	ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ! (Sat Sri Akal) - This is a universal Punjabi greeting and can be used at any time of the day. It’s equivalent to “Hello” in English.
                            <br></br>    2.	ਸ਼ੁਭ ਸਵੇਰ! (Subh savera) - This is used in the morning to say “Good morning.”
                            <br></br>    3.	ਸ਼ੁਭ ਸ਼ਾਮ! (Shubh Shaam) - Use this in the evening to say “Good evening.”
                            <br></br>   4.	ਤੁਹਾਡੀ ਰਾਤ ਖੁਸ਼ਿਆਲ ਹੋਵੇ!! (Tuhadi Raat Khushal howy) - This is a polite way to wish someone “Good night.”
                            <br></br><br></br>Great! You’ve learned the greetings. Now, let’s put them into practice with some role-playing exercises to simulate real-life situations.
                        </p>
                        <p className="colorBlack mt-4" style={{ fontWeight: 500, fontSize: 18, textAlign: 'justify' }}>You meet your friend in the morning. Greet each other using “ਸਤਸ੍ਰੀਅਕਾਲ!” (Sat Sri Kal).
                            <br></br>&nbsp; - Learner 1: ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ! (Sat Sri Kal)
                            <br></br>&nbsp; - Learner 2: ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ! (Sat Sri Kal)<br></br>
                            <br></br> Let’s create another scenario, for example, meeting a stranger
                            <br></br> You’re at a social event, and you want to greet someone you’ve never met before. Use ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ!” (Sat Sri Akal).
                            <br></br>&nbsp; - Learner 1: ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ! (Sat Sri Akal)
                            <br></br>&nbsp; - Stranger: ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ! (Sat Sri Akal)
                        </p>

                        <p className="text-center mt-5 head_font" style={{ fontSize: 30 }} >Why Choose Education Capsule for Punjabi Language Learning?</p>
                        <p className="colorBlack mt-4 mb-4" style={{ fontWeight: 500, fontSize: 18, textAlign: 'justify' }}>At Education Capsule, we believe that language learning should be an immersive and personalized experience, tailored to the unique needs and goals of each individual. Our team of experienced and dedicated Punjabi tutors is passionate about sharing the beauty of their language with students from all walks of life. Our personalized approach ensures that you receive the undivided attention and guidance you need to excel in your Punjabi language journey.</p>

                        <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12">
                            <div className="choose_card">
                                <p className="card_head">Personalized One-on-One Sessions</p>
                                <p className="card_sub_text">Our tutors provide individualized attention, catering to your specific learning needs and pace.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12">
                            <div className="choose_card">
                                <p className="card_head">Flexible Scheduling</p>
                                <p className="card_sub_text">We understand the importance of convenience. Enjoy the flexibility of scheduling your Punjabi lessons at a time that suits you best.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12">
                            <div className="choose_card">
                                <p className="card_head">Experienced Punjabi Tutors</p>
                                <p className="card_sub_text">Learn from native speakers and experienced tutors who bring cultural nuances into the language learning experience.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12">
                            <div className="choose_card">
                                <p className="card_head">Comprehensive Curriculum</p>
                                <p className="card_sub_text">Our curriculum covers both written and verbal aspects of the Punjabi language, ensuring a holistic learning experience.</p>
                            </div>
                        </div>

                        <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 mt-3">
                            <div className="choose_card">
                                <p className="card_head">Interactive Learning Materials</p>
                                <p className="card_sub_text">Engage in dynamic lessons with interactive materials that make the learning process enjoyable and effective.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 mt-3">
                            <div className="choose_card">
                                <p className="card_head">Commitment to Success</p>
                                <p className="card_sub_text">We provide ongoing support and guidance throughout your learning journey to ensure that you reach your full potential.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 mt-3">
                            <div className="choose_card">
                                <p className="card_head">Cultural<br></br> Insights</p>
                                <p className="card_sub_text">Beyond language proficiency, our tutors provide insights into Punjabi culture, including idioms, expressions, and cultural nuances.</p>
                            </div>
                        </div>
                        <div className="col-md-3 col-lg-3 col-sm-12 col-xl-3 col-xs-12 mt-3">
                            <div className="choose_card">
                                <p className="card_head">Practical Application</p>
                                <p className="card_sub_text">Apply your knowledge through practical exercises, role-play scenarios, and real-life conversations.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <p className="text-center mt-5 head_font" >Education Capsule's Punjabi Curriculum</p>
                <div className="review_div bg-black mt-5" style={{ padding: 30 }}>
                    <div className="row  mb-5" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12">
                            <div className="curr_card">
                                <img alt="gurumukhi" src={gurumukhi} />
                                <p className="curr_head">Gurmukhi Akharmala Mastery</p>
                                <p className="curr_sub_text">Learn the intricacies of the Gurmukhi script, the foundation of Punjabi writing. Explore two-letter, three-letter, and four-letter words to build a strong vocabulary foundation.</p>
                            </div>
                            <div className="curr_card">
                                <img alt="practical_lang" src={practical_lang} />
                                <p className="curr_head">Practical Language Use</p>
                                <p className="curr_sub_text">Discover essential conversational elements like greetings and expressions to navigate real-life scenarios in Punjabi-speaking communities.</p>
                            </div>
                            <div className="curr_card">
                                <img alt="veg" src={veg} />
                                <p className="curr_head">Colors, Fruits, and Vegetables</p>
                                <p className="curr_sub_text">Expand your vocabulary to include a spectrum of colors and everyday items, enhancing your ability to express and understand.</p>
                            </div>
                            <div className="curr_card">
                                <img alt="comman_word" src={comman_word} />
                                <p className="curr_head">Commonly Used Words</p>
                                <p className="curr_sub_text">Master frequently used words, empowering you to communicate effectively in various contexts.</p>
                            </div>
                        </div>

                        <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12 d-flex align-items-center justify-content-center">
                            <div className="vertical-line"></div>
                        </div>

                        <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 ">
                            <div className="curr_card">
                                <img alt="matrawas" src={matrawas} />
                                <p className="curr_head">Matrawas</p>
                                <p className="curr_sub_text">Dive into the nuances of Matras, including Kanna, Sihari, Bihari, Aunkarh, Dulainkarh, Laan, Dulawa, Horha, Kanaurha, Bindi, Tippi, and Adhak. </p>
                            </div>
                            <div className="curr_card">
                                <img alt="date" src={date} />
                                <p className="curr_head">Days, Months, and Seasons</p>
                                <p className="curr_sub_text">Acquire the linguistic skills to discuss time-related concepts confidently in Punjabi.</p>
                            </div>
                            <div className="curr_card">
                                <img alt="animal" src={animal} />
                                <p className="curr_head">Animals and Birds</p>
                                <p className="curr_sub_text">Explore the fauna of Punjabi conversations, incorporating animal and bird names into your language reserve.</p>
                            </div>
                            <div className="curr_card">
                                <img alt="greting_pun" src={greting_pun} />
                                <p className="curr_head">Greeting in Punjabi</p>
                                <p className="curr_sub_text">Learn the art of greeting in Punjabi, understanding the cultural nuances associated with different expressions.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-4">

                    <p className="colorBlack mt-4 text-center benifit_text" >Introductions and Personal Questions</p>
                    <p className="colorBlack mt-4" style={{ fontWeight: 500, fontSize: 22, textAlign: 'center' }}>ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ! (Sat Sri Akal)! Let’s start our next lesson by quickly reviewing the greetings we learned in the previous session.<br></br> Remember, greetings are the foundation of polite conversations in Punjabi.</p>
                </div>
                <div className="review_course row">
                    <div className="col-md-3">
                        <img src={benifit_1} alt="benifit_1" className="why_edu1" />
                    </div>
                    <div className="col-md-6 mt-5">
                        <p className="benifit_head">Personal Introductions:</p>
                        <p className="benifit_sub">Today, we’re going to learn how to introduce ourselves and ask for someone’s name in Punjabi. This is essential for building connections and starting conversations.
                            <br></br>    1. ਤੁਹਾਡਾ ਨਾਮ ਕੀ ਹੈ? (Tuhada name ki ha?) - This is how you ask someone, “What is your name?” It’s a formal way of asking.
                            <br></br>     2. ਮੇਰਾ ਨਾਮ [ਤੁਹਾਡਾ ਨਾਮ] ਹੈ। (Mera naam [tuhada naam] hai.) - This is how you respond when someone asks for your name. You say, “My name is [Your Name].”
                        </p>
                        <p className="benifit_head mt-4">Politeness and Responses:</p>
                        <p className="benifit_sub">In Punjabi, it’s common to ask about someone’s well-being. So, when you meet someone, you might want to ask, “How are you?”
                            <br></br>     1. ਤੁਸੀਂ ਕਿਵੇਂ ਹੋ? (tusi Kiven ho?) - This phrase means “How are you?”
                            <br></br>     Practice saying this phrase: ਤੁਸੀਂ ਕਿਵੇਂ ਹੋ? (tusi Kiven ho?)
                            <br></br>     2. ਮੈਂ ਠੀਕ ਹਾਂ। (Main theek haan.) - This is a response to “How are you?” It means “I’m fine.”
                            <br></br>     Try repeating this response: ਮੈਂ ਠੀਕ ਹਾਂ। (Main theek haan.)
                            <br></br><br></br>     Now, let’s put it all together. Imagine you’re meeting someone for the first time, and He/she will greet you. You can ask for his name and how he/she is doing, and he/she will respond.
                            <br></br>     - Learner: ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ! (Sat Sri Akal)
                            <br></br>     - Learner: ਤੁਹਾਡਾ ਨਾਮ ਕੀ ਹੈ?? (tuhada name ki ha?)
                            <br></br>     - He/She: ਮੇਰਾ ਨਾਮ [Your Name] ਹੈ। (Mera naam [Your Name] hai.)
                            <br></br>     - Learner: ਤੁਸੀਂ ਕਿਵੇਂ ਹੋ? (Tusi Kiven ho?)
                            <br></br>     - He/She: ਮੈਂ ਠੀਕ ਹਾਂ। (Main theek haan.)
                            <br></br><br></br>    Great job! By the end of this session, you’ve learned how to introduce yourself, ask for someone’s name, and inquire about how someone is doing in Punjabi. Keep practicing, and you’ll become more confident in these everyday conversations.
                        </p>
                    </div>
                    <div className="col-md-3" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15%' }}>
                        <img src={benifit_2} alt="benifit_1" />
                    </div>
                </div>
            </>
        )
    }
}


export default Punjabi