import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import * as Constant from './Constant'
import { ToastContainer, toast } from "react-toastify";

class ForgotPassword extends React.Component {

    constructor() {
        super();
        this.state = {
            email: '',
            isLoading: false,
            emailError: null,
        }
    }

    onClick = async () => {
        this.setState({ isLoading: true, })

        const formData = new FormData();
        formData.append("email", this.state.email);

        await fetch(Constant.forgotPassword, {
            method: 'POST',
            body: formData

        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false, email: '' })

                    toast.success('Please check your email to reset password..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });

                }
            })
    }

    validate = () => {
        let emailError = "";

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "Please Enter Email Address.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }


        if (emailError) {
            this.setState({
                emailError
            })

            return false
        }

        return true;

    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                emailError: null
            })
            this.onClick();
        }
    }


    render() {
        return (
            <>
                <LoadingOverlay
                    active={this.state.isLoading}
                    spinner
                    fadeSpeed={10}
                    text='Please wait...'
                >
                    <section id="loginnn">
                        <div class="container">
                            <div class="registration-form-outer loginnnn">
                                <div class="container register">
                                    <div class="row">
                                        <div class="col-md-8 register-right" alt="Education Capsule">
                                            <div class="tab-content" id="myTabContent">
                                                <ToastContainer />
                                                <h1 className="text-center mt-4">Forgot Password</h1>
                                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <div class="row register-form">
                                                        <div class="col-md-12">
                                                            <div class="form-group ">
                                                                <input type="email" name="email" class="form-control" placeholder="Email *" onChange={(event) =>
                                                                    this.setState({ email: event.target.value })
                                                                } value={this.state.email} />
                                                            </div>
                                                        </div>
                                                        {!!this.state.emailError && (
                                                            <text style={{ color: "red", marginLeft: "10px" }}>
                                                                {this.state.emailError}
                                                            </text>
                                                        )}
                                                        <div class="col-md-6 m-auto ">
                                                            <div class="form-group ">
                                                                <input type="submit" class="btnRegister" onClick={() => this.onSubmit()} value="Submit" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </LoadingOverlay>
            </>
        )
    }
}

export default ForgotPassword;