import React, { useState } from "react";

function OtpScreen() {
    const [inputs, setInputs] = useState(['', '', '', '', '', '']);

    const handleInputChange = (index, event) => {
        const { value } = event.target;
        const newInputs = [...inputs];
        newInputs[index] = value;

        setInputs(newInputs);

        if (value.length >= 1) {
            const nextIndex = index + 1;
            if (nextIndex < inputs.length) {
                document.getElementById(`input-${nextIndex}`).focus();
            }
        }
    };

    return (

        <section id="loginnn">
            <div className="container">
                <div className="registration-form-outer loginnnn">
                    <div className="container register">
                        <div className="row" style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <div className="col-md-12 register-right">
                                <div className="tab-content" id="myTabContent" >
                                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                        <div className="row tabheader" style={{ paddingBottom: '0%', marginTop: '50px' }}>
                                            <div className="col-md-12 text-center">
                                                <lable type="button" className="logintabview" >
                                                    <h1>Enter OTP:</h1>
                                                </lable>
                                            </div>
                                        </div>
                                        <div className="row register-form login_form">
                                            <div className="col-md-2"></div>
                                            <div className="col-md-8" style={{ display: 'flex', justifyContent: 'center' }}>
                                                <div className="inputs">
                                                    {inputs.map((input, index) => (
                                                        <input
                                                            key={index}
                                                            id={`input-${index}`}
                                                            className="input"
                                                            type="text"
                                                            inputMode="numeric"
                                                            maxLength="1"
                                                            value={input}
                                                            onChange={(event) => handleInputChange(index, event)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-md-2"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}



export default OtpScreen;