import React from "react";

import emoji1 from '../assets/img/emoji1.png'
import emoji2 from '../assets/img/emoji2.png'
import emoji3 from '../assets/img/emoji3.png'
import emoji4 from '../assets/img/emoji4.png'
import emoji5 from '../assets/img/emoji5.png'

import * as Constant from './Constant'

class StudentFeedback extends React.Component {

    constructor() {
        super();
        this.state = {
            enjoy_session: '',
            learning_resource: '',
            understandable: '',
            audio_connection: '',
            rating: '',
            message: '',
            isLoading: false
        }
    }

    onSubmit = async () => {
        this.setState({ isLoading: true })

        var userId = await localStorage.getItem('userId');
        var tutorId = await localStorage.getItem('tutorId');

        const formData = new FormData();
        formData.append("user_id", userId);
        formData.append("tutor_id", tutorId);
        formData.append("enjoy_session", this.state.enjoy_session);
        formData.append("learning_resource", this.state.learning_resource);
        formData.append("understandable", this.state.understandable);
        formData.append("audio_connection", this.state.audio_connection);
        formData.append("rating", this.state.rating);
        formData.append("message", this.state.message);

        console.log(formData);

        await fetch(Constant.studentFeedback, {
            method: "POST",
            body: formData
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false
                    })
                    window.location.href = Constant.BaseUrl + 'thank-you-feedback'
                } else {
                    this.setState({
                        isLoading: false
                    })
                }
            })
    }



    render() {
        return (
            <>
                <section id="feature" class="atf-section-padding tutor-page student-feedback studentfeebback">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="atf-section-title text-center wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                    <div class="student-feedback-page">
                                        <h1 style={{ color: '#000' }}>Student Feedback Form</h1>
                                        <div class="student-feedback-form-outer">
                                            <div class="app">
                                                <h4>How much did you enjoy the session?</h4>
                                                <div class="container">
                                                    <div class="item">
                                                        <label for="0">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                enjoy_session: event.target.value
                                                            })} name="enjoy_session" id="0" value="1" />
                                                            <span>{
                                                                this.state.enjoy_session == 1 ? <img src={emoji1} alt="emoji" className="emojione_checked" /> : <img src={emoji1} alt="emoji"/>
                                                            } </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="1">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                enjoy_session: event.target.value
                                                            })} name="enjoy_session" id="1" value="2" />
                                                            <span>{
                                                                this.state.enjoy_session == 2 ? <img src={emoji2} alt="emoji" className="emojitwo_checked" /> : <img src={emoji2} alt="emoji" />
                                                            }</span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="2">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                enjoy_session: event.target.value
                                                            })} name="enjoy_session" id="2" value="3" />
                                                            <span>{
                                                                this.state.enjoy_session == 3 ? <img src={emoji3} alt="emoji" className="emojithree_checked" /> : <img src={emoji3} alt="emoji"/>
                                                            } </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="3">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                enjoy_session: event.target.value
                                                            })} name="enjoy_session" id="3" value="4" />
                                                            <span>{
                                                                this.state.enjoy_session == 4 ? <img src={emoji4} alt="emoji" className="emojifour_checked" /> : <img src={emoji4} alt="emoji" />
                                                            }   </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="4">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                enjoy_session: event.target.value
                                                            })} name="enjoy_session" id="4" value="5" />
                                                            <span>{
                                                                this.state.enjoy_session == 5 ? <img src={emoji5} alt="emoji" className="emojifive_checked" /> : <img src={emoji5} alt="emoji" />
                                                            } </span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="app">
                                                <h4>How useful were the learning resources?</h4>
                                                <div class="container">
                                                    <div class="item">
                                                        <label for="01">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                learning_resource: event.target.value
                                                            })} name="learning_resource" id="01" value="1" />
                                                            <span>{
                                                                this.state.learning_resource == 1 ? <img src={emoji1} alt="emoji" className="emojione_checked" /> : <img src={emoji1} alt="emoji" />
                                                            }   </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="11">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                learning_resource: event.target.value
                                                            })} name="learning_resource" id="11" value="2" />
                                                            <span>{
                                                                this.state.learning_resource == 2 ? <img src={emoji2} alt="emoji" className="emojitwo_checked" /> : <img src={emoji2} alt="emoji" />
                                                            }   </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="21">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                learning_resource: event.target.value
                                                            })} name="learning_resource" id="21" value="3" />
                                                            <span>{
                                                                this.state.learning_resource == 3 ? <img src={emoji3} alt="emoji" className="emojithree_checked" /> : <img src={emoji3} alt="emoji" />
                                                            }   </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="31">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                learning_resource: event.target.value
                                                            })} name="learning_resource" id="31" value="4" />
                                                            <span>{
                                                                this.state.learning_resource == 4 ? <img src={emoji4} alt="emoji" className="emojifour_checked" /> : <img src={emoji4} alt="emoji" />
                                                            }  </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="41">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                learning_resource: event.target.value
                                                            })} name="learning_resource" id="41" value="5" />
                                                            <span>{
                                                                this.state.learning_resource == 5 ? <img src={emoji5} alt="emoji" className="emojifive_checked" /> : <img src={emoji5} alt="emoji"/>
                                                            }   </span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="app">
                                                <h4>How understandable was the tutor?</h4>
                                                <div class="container">
                                                    <div class="item">
                                                        <label for="02">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                understandable: event.target.value
                                                            })} name="understandable" id="02" value="1" />
                                                            <span>{
                                                                this.state.understandable == 1 ? <img src={emoji1} alt="emoji" className="emojione_checked" /> : <img src={emoji1}  alt="emoji"/>
                                                            }   </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="12">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                understandable: event.target.value
                                                            })} name="understandable" id="12" value="2" />
                                                            <span>{
                                                                this.state.understandable == 2 ? <img src={emoji2} className="emojitwo_checked" /> : <img src={emoji2} />
                                                            }    </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="22">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                understandable: event.target.value
                                                            })} name="understandable" id="22" value="3" />
                                                            <span>{
                                                                this.state.understandable == 3 ? <img src={emoji3} alt="emoji" className="emojithree_checked" /> : <img src={emoji3} alt="emoji" />
                                                            }  </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="32">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                understandable: event.target.value
                                                            })} name="understandable" id="32" value="4" />
                                                            <span>{
                                                                this.state.understandable == 4 ? <img src={emoji4} alt="emoji" className="emojifour_checked" /> : <img src={emoji4} alt="emoji" />
                                                            }   </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="42">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                understandable: event.target.value
                                                            })} name="understandable" id="42" value="5" />
                                                            <span>{
                                                                this.state.understandable == 5 ? <img src={emoji5} alt="emoji" className="emojifive_checked" /> : <img src={emoji5} alt="emoji"/>
                                                            }  </span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="app">
                                                <h4>How was the audio connection?</h4>
                                                <div class="container">
                                                    <div class="item">
                                                        <label for="03">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                audio_connection: event.target.value
                                                            })} name="audio_connection" id="03" value="1" />
                                                            <span>{
                                                                this.state.audio_connection == 1 ? <img src={emoji1} alt="emoji" className="emojione_checked" /> : <img src={emoji1}  alt="emoji"/>
                                                            }  </span >
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="13">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                audio_connection: event.target.value
                                                            })} name="audio_connection" id="13" value="2" />
                                                            <span>{
                                                                this.state.audio_connection == 2 ? <img src={emoji2} alt="emoji" className="emojitwo_checked" /> : <img src={emoji2} alt="emoji" />
                                                            } </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="23">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                audio_connection: event.target.value
                                                            })} name="audio_connection" id="23" value="3" />
                                                            <span>{
                                                                this.state.audio_connection == 3 ? <img src={emoji3} alt="emoji" className="emojithree_checked" /> : <img src={emoji3} alt="emoji" />
                                                            } </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="33">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                audio_connection: event.target.value
                                                            })} name="audio_connection" id="33" value="4" />
                                                            <span>{
                                                                this.state.audio_connection == 4 ? <img src={emoji4} alt="emoji" className="emojifour_checked" /> : <img src={emoji4} alt="emoji" />
                                                            } </span>
                                                        </label>
                                                    </div>

                                                    <div class="item">
                                                        <label for="43">
                                                            <input class="radio" type="radio" onClick={(event) => this.setState({
                                                                audio_connection: event.target.value
                                                            })} name="audio_connection" id="43" value="5" />
                                                            <span>{
                                                                this.state.audio_connection == 5 ? <img src={emoji5} alt="emoji" className="emojifive_checked" /> : <img src={emoji5} alt="emoji" />
                                                            } </span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>

                                            <h4>Overall rating</h4>
                                            <div class="rate">
                                                <input type="radio" id="star5" onClick={(event) => this.setState({
                                                    rating: event.target.value
                                                })} name="rating" value="5" />
                                                <label for="star5" title="text">5 stars</label>
                                                <input type="radio" id="star4" onClick={(event) => this.setState({
                                                    rating: event.target.value
                                                })} name="rating" value="4" />
                                                <label for="star4" title="text">4 stars</label>
                                                <input type="radio" id="star3" onClick={(event) => this.setState({
                                                    rating: event.target.value
                                                })} name="rating" value="3" />
                                                <label for="star3" title="text">3 stars</label>
                                                <input type="radio" id="star2" onClick={(event) => this.setState({
                                                    rating: event.target.value
                                                })} name="rating" value="2" />
                                                <label for="star2" title="text">2 stars</label>
                                                <input type="radio" id="star1" onClick={(event) => this.setState({
                                                    rating: event.target.value
                                                })} name="rating" value="1" />
                                                <label for="star1" title="text">1 star</label>
                                            </div>
                                            <div class="rating-text-area"> <textarea id="w3review" name="message" onChange={(event) => this.setState({
                                                message: event.target.value
                                            })} rows="4" cols="55" placeholder="Message: "></textarea></div>
                                            <button type="submit" class="btn btn-primary" onClick={() => this.onSubmit()} data-dismiss="modal" aria-label="Close">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}


export default StudentFeedback