import React, { Component } from 'react'
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import * as Constant from '../Constant'
import reviewgoogle from '../../assets/img/newhomepage/googlereview1.png'
import signup from '../../assets/img/newhomepage/sign_up.png'
import request_demo from '../../assets/img/newhomepage/request_demo.png'
import start_session from '../../assets/img/newhomepage/start_session.png'
import trust_pilot from '../../assets/img/trust_pilot.png'
import LazyVideo from '../../components/LazyVideo'

class SubjectDetailsNew extends Component {

    constructor() {
        super()

        this.state = {
            isLoadingProfile: false,
            role_id: '',

            nameError: null,
            emailError: null,
            phoneError: null,
            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            captchError: null,
            num1: Math.floor(Math.random() * 10) + 1,
            num2: Math.floor(Math.random() * 10) + 1,
            answer: '',
            isCorret: '',

            SubjectList: [],
            subject_id: '',
            level_id: '',
            LevelList: [],
            level_idError: null,
            subject_idError: null,
            country_idError: null,
            subLevelId: '',
            SubLevelList: [],
            selectedSubject: '',
            subLevelName: '',
            selectedCourses: [],
            country_id: '',
            levelName: ''
        }


        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);

    }

    componentDidMount = () => {
        this.getSubject()
        this.getProfile()
    }

    getProfile = async () => {

        const userId = localStorage.getItem('userId');
        if (userId === null) {
            this.getSubLevel()
        } else {
            await fetch(Constant.getUserProfile + '/' + userId, {
                method: "GET"
            })

                .then(Response => Response.json())
                .then(data => {
                    if (data.success === true) {
                        this.setState({
                            selectedCourses: data.data.selectedCourses,
                            subLevelId: data.data.sub_level_id,
                            subLevelName: data.data.subLevelName[0].name
                        })
                    } else {
                        console.log('Not Found')
                    }
                }
                )

            this.getSubLevel()
        }


    }

    onSubmit = () => {
        console.log('onSubmit')
        if (this.validate()) {
            console.log('validate')
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }


    showSubjectDetails = async (id) => {

        var userId = localStorage.getItem('userId')
        var subjectId = localStorage.getItem('subjectId')

        await fetch(Constant.getUserMoreSub + '/' + userId + '/' + subjectId + '/' + id, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    window.location.href = Constant.BaseUrl + 'subject-details/' + id
                    // if (data.data[0].is_approved === 1) {
                    //     window.location.href = Constant.BaseUrl + 'subject-details/' + id
                    // } else {
                    //     window.location.href = Constant.BaseUrl + 'topic-details-new/' + id
                    // }
                }
            })


    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let level_idError = '';
        let subject_idError = '';
        let country_idError = '';
        let captchError = ''
        let expectedAnswer = this.state.num1 + this.state.num2;


        if (!this.state.name) {
            nameError = "Please Enter Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "The Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (this.state.level_id.length == 0) {
            level_idError = "Please select level"
        }

        if (this.state.subject_id.length == 0) {
            subject_idError = "Please select subject"
        }

        if (this.state.country_id.length == 0) {
            country_idError = "Please select country"
        }

        if (!this.state.phone) {
            phoneError = "Please Enter Contact Number"
        }

        if (!this.state.userInput) {
            captchError = "Please Enter Captcha Code"
        } else {
            if (parseInt(this.state.userInput, 10) !== expectedAnswer) {
                captchError = "Please Enter Valid Captcha"
            }
        }

        if (level_idError || subject_idError || nameError || emailError || phoneError || captchError || country_idError) {
            this.setState({
                level_idError,
                subject_idError,
                nameError,
                emailError,
                phoneError,
                captchError,
                country_idError
            });

            return false;
        }

        return true;
    }

    onEnrollDemo = async () => {
        this.setState({ isLoading: true })

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("country_id", this.state.country_id);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        userInput: '',
                        country_id: '',
                        subject_id: '',
                        level_id: ''

                    })

                    toast.success('Your request forwarded successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };

    handleChangeSubject = (e) => {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel = (e) => {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
    }

    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    console.log(data.data)
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    getLevel = async (value) => {

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoading: false
                    })
                }
            })
    }

    getSubLevel = async () => {

        var id = window.location.pathname.split('/')[2]

        await fetch(Constant.getSelectedSubLevel + '/' + id, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    console.log(data.data)
                    this.setState({
                        selectedSubject: data.data[0].subjectname,
                        SubLevelList: data.data,
                        levelName: data.data[0].levelName[0].levelname
                    })
                }
            })
    }

    render() {
        return (
            <>
                <div className='container'>
                    <div className='row main_div'>
                        <div className='col-md-12 key_stage_banner'>
                            <p className="text-center pt-3 pb-3 black_bg" style={{ fontWeight: 400, fontSize: 35, color: '#fff' }}>{this.state.selectedSubject} - {this.state.levelName}</p>
                            <div className='row mt-3'>
                                <div className='col-md-2'></div>
                                <div className='col-md-5 detail_banner'>
                                    <h1 className=" pt-3 pb-3 key_stage_bannertext" >Interactive lessons</h1>
                                    <p className=" pt-3 pb-3" style={{ textAlign: 'justify', fontWeight: 400, fontSize: 18, color: '#fff' }} >For {this.state.levelName}, Education Capsule provides personalized sessions that are engaging for kids.</p>
                                </div>
                                <div className='col-md-5 mt-1'>
                                    <div className="learning-form mt-5 vision" style={{ width: 420 }}>
                                        <h2>Book Free Lesson Class</h2>
                                        <p>Learn From Best Online Tutors</p>
                                        <div className="slider-form-outer">
                                            <div className="form-group">
                                                <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                                                {!!this.state.nameError && (
                                                    <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                        {this.state.nameError}
                                                    </text>
                                                )}

                                                <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" minLength="10" maxLength="10" />
                                                {!!this.state.phoneError && (
                                                    <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                        {this.state.phoneError}
                                                    </text>
                                                )}

                                                <input name="email" value={this.state.email} onChange={(event) =>
                                                    this.setState({ email: event.target.value })
                                                } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                                                {!!this.state.emailError && (
                                                    <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                                        {this.state.emailError}
                                                    </text>
                                                )}

                                                <select name="subject_id" id="subject_id" onChange={this.handleChangeSubject} value={this.state.subject_id} className="form-control country_dropdown mt-1">
                                                    <option value="">Select Subject</option>
                                                    {
                                                        this.state.SubjectList.map((item, i) => (
                                                            <option key={i} value={item.id}>{item.title}</option>
                                                        ))
                                                    }

                                                </select>
                                                {!!this.state.subject_idError && (
                                                    <text style={{ color: "#000", marginLeft: "10px" }}>
                                                        {this.state.subject_idError}
                                                    </text>
                                                )}

                                                {
                                                    this.state.LevelList.length === 0 ? <select className="form-control mt-1"><option>Level Not Found</option></select> :
                                                        <select name="level_id" autoComplete="on" value={this.state.level_id} id="level_id" onChange={this.handleChangeLevel} className="form-control mt-1">
                                                            <option value="">Select Level</option>
                                                            {
                                                                this.state.LevelList.map((item, i) => (
                                                                    <option key={i} value={item.id}>{item.name}</option>
                                                                ))
                                                            }

                                                        </select>
                                                }
                                                {!!this.state.level_idError && (
                                                    <text style={{ color: "#000", marginLeft: "10px" }}>
                                                        {this.state.level_idError}
                                                    </text>
                                                )}

                                                <select name="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control country_dropdown mt-1">
                                                    <option value="">Select Country</option>
                                                    <option value="13" >Australia</option>
                                                    <option value="99" >India</option>
                                                    <option value="225" >United Kingdom</option>
                                                    <option value="226" >United States</option>
                                                </select>
                                                {!!this.state.country_idError && (
                                                    <text style={{ color: "#000", marginLeft: "10px" }}>
                                                        {this.state.country_idError}
                                                    </text>
                                                )}


                                                <div className="d-flex align-items-center robot_input">
                                                    <span style={{ marginRight: 10 }}>I’m not a robot</span>
                                                    <span className="equation colorBlack"> {this.state.num1} + {this.state.num2} =</span>
                                                    <input
                                                        type="text"
                                                        className="form-control text_field_robot"
                                                        name="userInput "
                                                        value={this.state.userInput}
                                                        onChange={(e) => this.setState({ userInput: e.target.value })}
                                                    />
                                                </div>
                                                {!!this.state.captchError && (
                                                    <text style={{ color: "black", marginLeft: "10px" }}>
                                                        {this.state.captchError}
                                                    </text>
                                                )}
                                                <div style={{ width: 155, marginLeft: 'auto', marginRight: 'auto' }}>
                                                    <button
                                                        type="submit"
                                                        name="submit"
                                                        onClick={() => this.onSubmit()}
                                                        className="btnRegister enrolldemo"
                                                    >
                                                        {this.state.isLoading ? (
                                                            <ClipLoader
                                                                type="TailSpin"
                                                                color="#ffffff"
                                                                height="20"
                                                                width="20"
                                                            />
                                                        ) : (
                                                            "Book Demo Lessons"
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <ToastContainer />
                    <p className="text-center mt-5" style={{ fontWeight: 500, fontSize: 35, color: '#000' }}>Get the best education for your kids</p>

                    <div className='row'>
                        {
                            this.state.SubLevelList.map((item, i) => (
                                <div className='col-md-6'>
                                    {
                                        item.SelectedLevel.length !== 0 ? <>
                                            <p className="text-center mt-5" style={{ fontWeight: 700, fontSize: 25, color: '#000' }}>{this.state.selectedSubject} - {item.name}</p>
                                            <p className="text-center mt-5" style={{ fontWeight: 500, fontSize: 15, color: '#000' }}>{item.description}
                                            </p>
                                            <div class="mt-4 mb-3 mobile"><a class="btn btn-info student-registration" href="javascript:void(0)" onClick={() => this.showSubjectDetails(item.id)} style={{ color: '#fff', borderRadius: 30, backgroundColor: '#B1257B' }}><span>Review Courses</span></a></div>

                                        </> : ''
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div >

                <div className="mt-5 bg-black" style={{ border: 'none' }}>
                    <div className="row">
                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11">
                                    <div className="reviwesfont1">5000+</div>
                                    <div className="reviwesfont2">Student Enrolled</div>
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11 text-center">
                                    <img src={trust_pilot} alt="star" />
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 text-center" style={{ padding: 50 }}>
                            <img src={reviewgoogle} alt='reviews' />
                        </div>

                    </div>
                </div>

                <p className="text-center mt-5" style={{ fontWeight: 600, fontSize: 40, color: '#000' }}>How do we work?</p>

                <div className='row mt-5'>
                    <div className='col-md-1'></div>
                    <div className='col-md-4 mt-5'>
                        <p className='mt-5' style={{ textAlign: 'justify', fontWeight: 700, fontSize: 25, color: '#000' }} >One-to-one Learning</p>
                        <p className='mt-2' style={{ textAlign: 'justify', fontWeight: 500, fontSize: 18, color: '#000' }} >Our adaptive one-to-one online tutoring is trusted and recommended by thousands of parents and teachers across the UK.
                        </p>

                        <p className='mt-5' style={{ textAlign: 'justify', fontWeight: 700, fontSize: 25, color: '#000' }} >Conceptual  Video Lessons</p>
                        <p className='mt-2' style={{ textAlign: 'justify', fontWeight: 500, fontSize: 18, color: '#000' }} >At Education Capsule, we provide Conceptual online Video lessons for developing the fundamental concepts of students.
                        </p>

                        <p className='mt-5' style={{ textAlign: 'justify', fontWeight: 700, fontSize: 25, color: '#000' }} >Engaging Practice Quizzes</p>
                        <p className='mt-2' style={{ textAlign: 'justify', fontWeight: 500, fontSize: 18, color: '#000' }} >{this.state.selectedSubject} tutors at Education Capsule provide collaborative assistance to students by engaging them with practice questions and quizzes.
                        </p>

                        <p className='mt-5' style={{ textAlign: 'justify', fontWeight: 700, fontSize: 25, color: '#000' }} >Around The Clock Availability</p>
                        <p className='mt-2' style={{ textAlign: 'justify', fontWeight: 500, fontSize: 18, color: '#000' }} >Education Capsule provides 24*7 online tutoring services to the students with help of our dedicated professional {this.state.selectedSubject.toLowerCase()}s tutors.
                        </p>
                    </div>
                    <div className='col-md-1'></div>
                    <div className='col-md-6 video_bg' style={{ padding: 197 }}>
                        <LazyVideo />
                    </div>
                </div>

                <h2 className="largefont mt-5"  >Start your learning journey with us</h2>
                <div className="row mt-5">
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12  text-center">
                        <img src={signup} alt='signup'/>
                        <br></br>
                        <div style={{ display: 'flex' }}>
                            <a href="/register" className="lending-btn bookdemo" > Sign Up</a>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12 text-center" >
                        <img src={request_demo} alt='request_demo'/><br></br>
                        <div style={{ display: 'flex' }}>
                            <a href="/book-a-tutor" className="lending-btn bookdemo" > Request Lesson</a>
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-sm-12 col-xl-4 col-xs-12  text-center">
                        <img src={start_session} alt='request_demo'/><br></br>
                        <div style={{ display: 'flex' }}>
                            <a href="/book-a-tutor" class="lending-btn bookdemo">Start Lesson</a>
                        </div>
                    </div>
                </div>


            </>
        )
    }
}

export default SubjectDetailsNew
