import React from "react";
import { NavLink } from "react-router-dom";

import * as Constant from '../Constant'
import reviewgoogle from '../../assets/img/newhomepage/googlereview1.png'
import english_banner from '../../assets/img/subjects/english_banner.png'
import learn_eng1 from '../../assets/img/subjects/learn_eng1.png'
import learn_eng2 from '../../assets/img/subjects/learn_eng2.png'
import career from '../../assets/img/subjects/career.png'
import anywere from '../../assets/img/subjects/anywere.png'
import person from '../../assets/img/subjects/person.png'
import note from '../../assets/img/subjects/note.png'
import book from '../../assets/img/subjects/book.png'
import clock from '../../assets/img/subjects/clock.png'
import certified from '../../assets/img/subjects/certified.png'
import exploremore from '../../assets/img/subjects/exploremore.png'
import trust_pilot from '../../assets/img/trust_pilot.png'
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';

class English extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoadingProfile: false,
            role_id: '',


            nameError: null,
            emailError: null,
            phoneError: null,
            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            captchError: null,
            num1: Math.floor(Math.random() * 10) + 1,
            num2: Math.floor(Math.random() * 10) + 1,
            answer: '',
            isCorret: '',

            SubjectList: [],
            subject_id: '',
            level_id: '',
            LevelList: [],
            level_idError: null,
            subject_idError: null,
            country_idError: null,
            country_id: '',
            paperList: []
        }

        this.recaptchaRef = React.createRef();
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
    }

    componentDidMount = () => {
        this.getProfile();
        this.getSubject();
        this.getPapers();
    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }

    getPapers = async () => {
        this.setState({ isLoading: true })

        var subjectId = localStorage.getItem('subjectId')

        await fetch(Constant.getPapers + '/' + subjectId, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                this.setState({ paperList: data.data, isLoading: false })
            })
    }


    showPaper = (id, totalQuestion) => {
        if (totalQuestion === 0) {
            const url = Constant.BaseUrl + 'contact-us'
            window.location.href = url;
        } else {
            const url = Constant.BaseUrl + 'exam_papers/1/' + id;
            // window.open(url, '_blank');
            window.location.href = url;
        }
    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let level_idError = '';
        let subject_idError = '';
        let country_idError = '';


        if (!this.state.name) {
            nameError = "Please Enter Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "The Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (this.state.level_id.length == 0) {
            level_idError = "Please select level"
        }

        if (this.state.subject_id.length == 0) {
            subject_idError = "Please select subject"
        }

        if (this.state.country_id.length == 0) {
            country_idError = "Please select country"
        }

        if (!this.state.phone) {
            phoneError = "Please Enter Contact Number"
        } else {
            if (this.state.phone.length <= 7) {
                phoneError = "Phone number cannot be less than eight digit"
            }
        }

        if (level_idError || subject_idError || nameError || emailError || phoneError || country_idError) {
            this.setState({
                level_idError,
                subject_idError,
                nameError,
                emailError,
                phoneError,
                country_idError
            });

            return false;
        }

        return true;
    }

    onEnrollDemo = async () => {
        this.setState({ isLoading: true })

        const recaptchaValue = await this.recaptchaRef.current.executeAsync();

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("country_id", this.state.country_id);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);
        formData.append("recaptchaToken", recaptchaValue);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        userInput: '',
                        country_id: '',
                        subject_id: '',
                        level_id: ''

                    })

                    toast.success('Your request forwarded successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };

    handleChangeSubject = (e) => {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel = (e) => {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
    }

    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    getLevel = async (value) => {

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoading: false
                    })
                }
            })
    }

    getProfile = async () => {
        this.setState({ isLoadingProfile: true })

        const userId = localStorage.getItem('userId');

        await fetch(Constant.getUserProfile + '/' + userId, {
            method: "GET"
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        role_id: data.data.role_id,
                    })
                } else {
                    console.log('Not Found')
                }
            }
            )
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
                    <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 pt-5">
                        <p className=" mt-5 head_font" >Online English Tutors</p>

                        <p className="colorBlack mt-4 pt-3 pb-3 banner_sub_text" style={{ color: '#000', fontWeight: 600, fontSize: 18 }}>Improve your English skills with Education Capsule's comprehensive English programs. Our experienced instructors can help you learn everything from basic grammar to advanced communication techniques. Sign up today and start your journey to English fluency!</p>

                        <div className="mt-4 ">
                            <img alt="maths_banner" src={english_banner} className="banner_radius" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                        <div className="learning-form " style={{ marginTop: 100 }} >
                            <h2>Book Free Lesson Class</h2>
                            <p>Learn From Best Online Tutors</p>
                            <div className="slider-form-outer">
                                <div className="form-group">
                                    <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                                    {!!this.state.nameError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.nameError}
                                        </text>
                                    )}

                                    <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" minLength="10" maxLength="10" />
                                    {!!this.state.phoneError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.phoneError}
                                        </text>
                                    )}

                                    <input name="email" value={this.state.email} onChange={(event) =>
                                        this.setState({ email: event.target.value })
                                    } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                                    {!!this.state.emailError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.emailError}
                                        </text>
                                    )}

                                    <select name="subject_id" id="subject_id" onChange={this.handleChangeSubject} value={this.state.subject_id} className="form-control country_dropdown mt-1">
                                        <option value="">Select Subject</option>
                                        {
                                            this.state.SubjectList.map((item, i) => (
                                                <option key={i} value={item.id}>{item.title}</option>
                                            ))
                                        }

                                    </select>
                                    {!!this.state.subject_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.subject_idError}
                                        </text>
                                    )}

                                    {
                                        this.state.LevelList.length === 0 ? <select className="form-control mt-1"><option>Level Not Found</option></select> :
                                            <select name="level_id" autoComplete="on" value={this.state.level_id} onChange={this.handleChangeLevel} className="form-control mt-1">
                                                <option value="">Select Level</option>
                                                {
                                                    this.state.LevelList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    ))
                                                }

                                            </select>
                                    }
                                    {!!this.state.level_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.level_idError}
                                        </text>
                                    )}

                                    <select name="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control country_dropdown mt-1">
                                        <option value="">Select Country</option>
                                        <option value="13" >Australia</option>
                                        <option value="99" >India</option>
                                        <option value="225" >United Kingdom</option>
                                        <option value="226" >United States</option>
                                    </select>
                                    {!!this.state.country_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.country_idError}
                                        </text>
                                    )}


                                    <div className="d-flex align-items-center ">
                                        <ReCAPTCHA
                                            sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                            size="invisible"
                                            ref={this.recaptchaRef}
                                        />
                                    </div>
                                    <div className="col-sm-6 ml-auto" >
                                        <button
                                            type="submit"
                                            name="submit"
                                            onClick={() => this.onSubmit()}
                                            className="btnRegister enrolldemo"
                                        >

                                            {this.state.isLoading ? (
                                                <ClipLoader
                                                    type="TailSpin"
                                                    color="#ffffff"
                                                    height="20"
                                                    width="20"
                                                />
                                            ) : (
                                                "Book Demo Lessons"
                                            )}

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
                </div>

                <ToastContainer />

                <div className="mt-5 bg-black" style={{ border: 'none' }}>
                    <div className="row">
                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11">
                                    <div className="reviwesfont1">5000+</div>
                                    <div className="reviwesfont2">Student Enrolled</div>
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11 text-center">
                                    <img src={trust_pilot} alt="star" />
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 text-center" style={{ padding: 50 }}>
                            <img src={reviewgoogle} alt='reviews' />
                        </div>

                    </div>
                </div>

                <p className="text-center mt-5 head_font" >English - The Most Studied Language in the World</p>
                <p className="colorBlack mt-4 text-center" style={{ fontWeight: 500, fontSize: 18 }}>English is the most studied language. It is the only language with more than a billion learners worldwide. Approximately, 17% of the world’s population speaks English.<br></br> Learning English is a necessity as it plays a major role in our career growth and is an incredibly useful language to learn.</p>
                <div className="new_bg">

                    <div className="container">
                        <div className="  review_div" style={{ width: '100%', backgroundColor: '#A12170' }}>
                            <div className="row mt-5 pt-3 pb-5">
                                <div className="col-md-1 " ></div>
                                <div className="col-md-3 ">
                                    <p className="mt-4 text-center" style={{ fontWeight: 500, fontSize: 20, color: '#fff' }} >Official language <br></br> of  more than</p>
                                    <p className="mt-5 text-center" style={{ fontWeight: 600, fontSize: 90, color: '#fff' }} >50</p>
                                    <p className="mt-5 text-center" style={{ fontWeight: 600, fontSize: 40, color: '#fff' }}>Nations</p>
                                </div>
                                <div className="col-md-1 ">
                                    <div className="vertical-line left" style={{ marginTop: 35 }}></div>
                                </div>
                                <div className="col-md-2 ">
                                    <p className="mt-4 text-center" style={{ fontWeight: 500, fontSize: 20, color: '#fff' }} >English speakers <br></br> worldwide</p>
                                    <p className="mt-5 text-center" style={{ fontWeight: 600, fontSize: 90, color: '#fff' }} >1.35</p>
                                    <p className="mt-5 text-center" style={{ fontWeight: 600, fontSize: 40, color: '#fff' }}>billion</p>
                                </div>
                                <div className="col-md-1 ">
                                    <div className="vertical-line right" style={{ marginTop: 35 }}></div>
                                </div>

                                <div className="col-md-3 ">
                                    <p className="mt-4 text-center" style={{ fontWeight: 500, fontSize: 20, color: '#fff' }} >Around the globe,<br></br>Taught in over</p>
                                    <p className="mt-5 text-center" style={{ fontWeight: 600, fontSize: 90, color: '#fff' }} >188</p>
                                    <p className="mt-5 text-center" style={{ fontWeight: 600, fontSize: 40, color: '#fff' }}>Countries</p>
                                </div>
                                <div className="col-md-1" ></div>
                            </div>
                        </div>
                    </div>


                    <div className=" row mt-4 ">
                        <div className="col-md-2">
                            <img src={learn_eng1} alt="why Edu" style={{ marginTop: 55 }} />
                        </div>
                        <div className="col-md-8">
                            <p className="text-center mt-4" style={{ fontWeight: 700, fontSize: 30, color: '#000' }}>Why is Learning English important?</p>

                            <p className="one_to_one mt-4">IIt is no surprise that English is so widely spoken and studied. No matter what career path you select, learning English is a must to grow and prosper. Global material, such as news, politics, and entertainment, is frequently reported in English at the outset in many different countries. The global job market has created many new prospects for people. As such, by learning English, you can improve your career prospects.<br></br><br></br>

                                Being one of the most frequently used languages on earth also makes it easier to communicate internationally, especially in nations where it isn't always the official language. English language courses would be beneficial for you to improve your communication skills and thereby gain confidence by speaking effectively, which is required in every sphere of life.<br></br><br></br>

                                Being a language of international communication as well as media and the internet, it is becoming a necessity for people to speak English for socialisation and interaction. Having a better understanding of English will allow you to access a wide range of information from the internet, which will improve your learning as a student. Therefore, by learning English, you can enrich your career growth by accessing diverse opportunities!</p>
                        </div>
                        <div className="col-md-2 ">
                            <img src={learn_eng2} alt="why Edu" style={{ marginTop: 190 }} />
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 bg-black" style={{ borderRadius: 50 }}>
                            <div className="row pb-5" >
                                <p className="text-center mt-5 " style={{ fontWeight: 400, fontSize: 35, color: '#fff' }}>Get One to One Online English Tutor</p>

                                <div className="col-md-2"> </div>
                                <div className="col-md-8 mb-5">
                                    <p className=" mt-5 " style={{ fontWeight: 400, fontSize: 18, color: '#fff', textAlign: 'justify' }}>Education Capsule is an online tutoring platform with best-qualified English Tutors that provides one-on-one customized sessions to learners across the globe. Students can select their convenient time and avail of our service around the clock.<br></br><br></br>

                                        Our personalized and adaptive one-to-one tutoring is trusted and recommended by hundreds of parents and teachers across the UK. Our English tutor's design personalised sessions for students and help students to speak and learn English with confidence. We provide an inclusive and safe learning environment for students to improve their English speaking skills. </p>

                                </div>
                                <div className="col-md-2">
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <div className="row mt-5 ">
                        <div className="col-md-1"></div>
                        <div className="col-md-5 " style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="career_diveng sciencemb">
                                <div className="row ">
                                    <div className="col-md-6">
                                        <p className="career_text">Improve your <br></br>
                                            career prospects</p>
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <img src={career} alt="career"/>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>

                                <p className="career_font mt-3">No matter where you come from, learning a new language is beneficial. Being multilingual can improve your networking skills, negotiation skills, and understanding of international relations. It is especially useful because English is spoken in so many different countries that you can work anywhere in the world. As English is the official language of more than 50 nations, learning English means more job opportunities. You may even be eligible for additional promotions and travel opportunities based on your English proficiency.</p>
                            </div>
                        </div>
                        <div className="col-md-5" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="career_diveng sciencemb">
                                <div className="row ">
                                    <div className="col-md-6">
                                        <p className="career_text">Learn English<br></br>
                                            from Anywhere</p>
                                    </div>
                                    <div className="col-md-4 pt-2">
                                        <img src={anywere} alt="anywere"/>
                                    </div>
                                    <div className="col-md-2"></div>
                                </div>

                                <p className="career_font mt-3">Education Capsule provides everything, that a learner needs to become a confident and fluent English speaker. Improve your English proficiency by joining our English language courses. We provide tailor-made courses for English language learning, which include grammar (Basic and Intermediate), Reading Skills, Speaking Skills, Listening Skills, and Writing Skills. We believe that every person or student is different and has their learning style and preferences. One teaching style is specifically developed to cater to the needs of different learners.</p>
                            </div>

                        </div>
                        <div className="col-md-1"></div>
                    </div>

                    <p className="text-center mt-5 head_font">5 Reasons To Learn With Education Capsule</p>
                    <div className=" mt-5">
                        <div className="row" >
                            <div className="col-md-2"></div>
                            <div className="col-md-8 pb-5 bg-black" style={{ borderRadius: 50 }}>
                                <div className="row mt-4">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={person} alt="person" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 23, }}>Individual classes</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 20 }}>Education Capsule offers certified English tutors, guaranteeing expert guidance in language learning. Our dedicated tutors bring expertise and proficiency to enhance your English language skills effectively.</p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr className="horizontal_line line_width"></hr>
                                <div className="row mt-4">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={certified} alt="certified" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 23, }}>Certified Science Tutors</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 20 }}>Our English Tutors are well-qualified and expert in their field. They have completed our training courses and earned the necessary certifications to deliver their utmost professional services.  </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr className="horizontal_line line_width"></hr>
                                <div className="row mt-2">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={clock} alt="clock" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 23, }}>Round the clock support</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 20 }}>We offer one-on-one classes, ensuring individualized learning experiences. Our round-the-clock support ensures that assistance is readily available, making English learning an accessible experience for all.</p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr className="horizontal_line line_width"></hr>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={book} alt="book" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 23, }}>All Levels covered</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 20 }}>We provide English tuition to students of all levels from Key Stage 1 to GCSE programs. Apart from this we also provide online classes for improving English speaking skills, writing skills, reading skills, etc.</p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr className="horizontal_line line_width"></hr>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={note} alt="note" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 23, }}>Competitive prices</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 20 }}>We pride ourselves on offering competitive prices and making quality education accessible to all without breaking the bank. Our commitment to affordability ensures that learning remains affordable and inclusive.</p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </div>

                <div className="bg-black mt-5" style={{ border: 'none' }}>
                    <div className="row pb-5">
                        <div className="col-md-6 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div >
                                <p className="pt-5" style={{ textAlign: 'justify', fontWeight: 700, fontSize: 39, color: '#fff' }}>Build your<br></br><br></br> Child’s Future </p>
                                <p className="mt-4" style={{ textAlign: 'justify', color: '#fff', fontSize: 17 }}>Invest in their education<br></br> today for a tomorrow <br></br> filled with endless opportunities.</p>
                            </div>
                        </div>

                        <div className="col-md-6 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div>
                                <img src={exploremore} alt="Exploremore" className="mt-4" style={{ width: 320 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default English