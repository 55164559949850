import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";

//images
import logo_new from '../assets/img/logo-new.png'
import * as Constant from '../components/Constant'
import { ToastContainer, toast } from "react-toastify";

import LoadingOverlay from 'react-loading-overlay';

function VerifyEmail() {
    const { id } = useParams();
    const [state, setState] = useState({
        isLoading: true,
        email: '',
        password: '',
        role_id: '',
        isActive: false,

        emailError: null,
        passwordError: null,
        callApi: 0
    }
    )

    const storeRoleId = async (roleId) => {
        try {
            localStorage.setItem("roleId", roleId);
            console.log(roleId);
        } catch (e) { }
    }

    const verifyUserEmail = async () => {
        await fetch(Constant.verifyEmailUrl + "/" + id, {
            method: "POST"
        })
            .then(Response => Response.json())
            .then(data => {

                toast.success('Your Account Verified successfully..', {
                    position: 'top-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setState({
                    ...state,
                    callApi: 1
                })

                console.log(data)
            })
    }

    const validate = () => {
        let emailError = "";
        let passwordError = "";

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!state.email) {
            emailError = "Please Enter Email Address.";
        } else {
            if (!reg.test(state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (!state.password) {
            passwordError = "Please Enter Password.";
        }

        if (emailError || passwordError) {
            setState({
                ...state,
                emailError,
                passwordError
            })

            return false
        }

        return true;

    }

    useEffect(() => {
        if (state.callApi == 0) {
            verifyUserEmail();
        }
        console.log('use effect')
    }, [])

    const onSubmit = () => {
        if (validate()) {
            setState({
                ...state,
                emailError: null,
                passwordError: null
            })
            onLogin();
        }
    }

    const storeUserId = async (userId) => {
        try {
            localStorage.setItem("userId", userId);
            console.log(userId);
        } catch (e) { }
    }

    const onLogin = async () => {
        setState({ ...state, isLoading: true, isActive: true })


        const formData = new FormData();
        formData.append("email", state.email);
        formData.append("password", state.password);

        await fetch(Constant.LoginUrl, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                // console.log(data.data)
                if (data.success === true) {
                    storeRoleId(data.data.role_id)
                    storeUserId(data.data.id)
                    setState({
                        email: "",
                        password: "",
                        isLoading: false,
                        isActive: false
                    });

                    if (data.data.role_id === 3) {
                        window.location.href = Constant.BaseUrl + "student-dashboard"
                    } else if (data.data.role_id === 4) {
                        window.location.href = Constant.BaseUrl + "tutor-dashboard"
                        // navigate("/tutor-dashboard");
                    } else {
                        window.location.href = Constant.BaseUrl
                        // navigate("/");
                    }

                } else {
                    setState({
                        isActive: false
                    })
                    toast.error(data.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
    }


    return (
        <LoadingOverlay
            active={state.isActive}
            spinner
            fadeSpeed={10}
            text='Logining...'
        >
            <section id="loginnn">
                <div className="container">
                    <div className="registration-form-outer loginnnn">
                        <div className="container register">
                            <div className="row" style={{ marginLeft: '10px', marginRight: '10px' }}>

                                <div className="col-md-8 register-right">
                                    <div className="tab-content" id="myTabContent" >
                                        <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">

                                            <div className="row tabheader" style={{ paddingBottom: '0%', marginTop: '50px' }}>
                                                <div className="col-md-12 text-center">
                                                    <ToastContainer />
                                                    <lable type="button" className="logintabview" >
                                                        <h1>Sign in</h1>
                                                    </lable>
                                                </div>
                                            </div>
                                            <div className="row register-form login_form" >
                                                <div className="col-md-3">
                                                    <h4>Email </h4>
                                                </div>

                                                <div className="col-md-9">
                                                    <div className="form-group ">
                                                        <input type="email" onChange={(event) =>
                                                            setState({ ...state, email: event.target.value })
                                                        } className="form-control" placeholder="Email *" />
                                                    </div>
                                                    {!!state.emailError && (
                                                        <text style={{ color: "red", marginLeft: "10px" }}>
                                                            {state.emailError}
                                                        </text>
                                                    )}
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>Password </h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <input type="password" name="password" onChange={(event) =>
                                                            setState({ ...state, password: event.target.value })
                                                        } className="form-control" placeholder="Password *" />
                                                    </div>
                                                    {!!state.passwordError && (
                                                        <text style={{ color: "red", marginLeft: "10px" }}>
                                                            {state.passwordError}
                                                        </text>
                                                    )}
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp;</h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="form-group">
                                                        <a href="{!! url('forgot-password') !!}"><h5 >Forgot Password?</h5></a>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp; </h4>
                                                </div>
                                                <div className="col-md-6 loginbutton" >
                                                    <div className="form-group" >
                                                        <input type="submit" className="btnRegister" onClick={() => onSubmit()} value="Login" />
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp;</h4>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="form-group " style={{ marginTop: '25px', textAlign: "center" }}>
                                                        <h3>Or</h3>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <h4>&nbsp;</h4>
                                                </div>
                                                <div className="col-md-9 " >
                                                    <div className="form-group " style={{ textAlign: "center" }}>
                                                        <h4>New to Education Capsule? <NavLink to="/register" style={{ color: '#c42c89' }}>join now</NavLink></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </LoadingOverlay>
    )

}


export default VerifyEmail;