import React from "react";
import './subject.css'

import { NavLink } from "react-router-dom";
import * as Constant from '../Constant'

import maths_banner from '../../assets/img/subjects/maths_banner.png'
import reviewgoogle from '../../assets/img/newhomepage/googlereview1.png'
import why_edu1 from '../../assets/img/subjects/why_edu1.png'
import why_edu2 from '../../assets/img/subjects/why_edu2.png'
import enganing from '../../assets/img/subjects/enganing.png'
import interctive from '../../assets/img/subjects/interctive.png'
import advance_child from '../../assets/img/subjects/advance_child.png'
import earth from '../../assets/img/subjects/earth.png'
import breather from '../../assets/img/subjects/breather.png'
import sort from '../../assets/img/subjects/sort.png'
import exploremore from '../../assets/img/subjects/exploremore.png'
import trust_pilot from '../../assets/img/trust_pilot.png'
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';


class Maths extends React.Component {

  constructor() {
    super()

    this.state = {
      isLoading: false,
      selectedLevelId: [],
      modalshow: false,
      subLevelId: '',
      role_id: '',

      nameError: null,
      emailError: null,
      phoneError: null,
      captchaText: '',
      userInput: '',
      isCaptchaValid: false,
      captchError: null,
      num1: Math.floor(Math.random() * 10) + 1,
      num2: Math.floor(Math.random() * 10) + 1,
      answer: '',
      isCorret: '',

      SubjectList: [],
      subject_id: '',
      level_id: '',
      LevelList: [],
      level_idError: null,
      subject_idError: null,
      country_idError: null,
      country_id: '',
      moreSubjects: [],
      subLevelList: [],
      paperList: []

    }

    this.recaptchaRef = React.createRef();
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    this.handleChangeLevel = this.handleChangeLevel.bind(this);
  }

  showTopic = (id) => {
    window.location.href = Constant.BaseUrl + 'subject-details/' + id
  }

  componentDidMount = () => {
    this.getProfile();
    this.getSubject();
    this.getPapers();
  }

  getProfile = async () => {
    this.setState({ isLoadingProfile: true })

    const userId = localStorage.getItem('userId');

    await fetch(Constant.getUserProfile + '/' + userId, {
      method: "GET"
    })

      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({
            role_id: data.data.role_id,
            isLoadingProfile: false,
            subLevelId: data.data.sub_level_id,
            moreSubjects: data.data.selectedCourses
          })
        } else {
          console.log('Not Found')
        }
      }
      )
    this.getLevels()
  }

  showModel = () => {
    this.setState({
      modalshow: true
    })
  }

  handleClose = () => {
    this.setState({
      modalshow: false
    })
  }


  onSubmit = () => {
    if (this.validate()) {
      this.setState({
        nameError: null,
        emailError: null,
        phoneError: null,
      })
      this.onEnrollDemo();
    }
  }

  getPapers = async () => {
    this.setState({ isLoading: true })

    var subjectId = localStorage.getItem('subjectId')

    await fetch(Constant.getPapers + '/' + subjectId, {
      method: 'GET'
    })

      .then(Response => Response.json())
      .then(data => {
        this.setState({ paperList: data.data, isLoading: false })
      })
  }

  showPaper = (id, totalQuestion) => {
    if (totalQuestion === 0) {
      const url = Constant.BaseUrl + 'contact-us'
      window.location.href = url;
    } else {
      const url = Constant.BaseUrl + 'exam_papers/1/' + id;
      // window.open(url, '_blank');
      window.location.href = url;
    }

  }

  validate = () => {
    let nameError = '';
    let emailError = '';
    let phoneError = '';
    let level_idError = '';
    let subject_idError = '';
    let country_idError = '';


    if (!this.state.name) {
      nameError = "Please Enter Name"
    }

    var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
    if (!this.state.email) {
      emailError = "The Email Address cannot be empty.";
    } else {
      if (!reg.test(this.state.email)) {
        emailError = "Please enter a valid Email Address.";
      }
    }

    if (this.state.level_id.length == 0) {
      level_idError = "Please select level"
    }

    if (this.state.subject_id.length == 0) {
      subject_idError = "Please select subject"
    }

    if (this.state.country_id.length == 0) {
      country_idError = "Please select country"
    }

    if (!this.state.phone) {
      phoneError = "Please Enter Contact Number"
    } else {
      if (this.state.phone.length <= 7) {
        phoneError = "Phone number cannot be less than eight digit"
      }
    }

    if (level_idError || subject_idError || nameError || emailError || phoneError || country_idError) {
      this.setState({
        level_idError,
        subject_idError,
        nameError,
        emailError,
        phoneError,
        country_idError
      });

      return false;
    }

    return true;
  }

  onEnrollDemo = async () => {
    this.setState({ isLoading: true })

    const recaptchaValue = await this.recaptchaRef.current.executeAsync();

    const formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("phone", this.state.phone);
    formData.append("country_id", this.state.country_id);
    formData.append("subject_id", this.state.subject_id);
    formData.append("level_id", this.state.level_id);
    formData.append("recaptchaToken", recaptchaValue);

    await fetch(Constant.FreeDemo, {
      method: 'POST',
      body: formData,
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({ isLoading: false })
          this.setState({
            name: '',
            email: '',
            phone: '',
            userInput: '',
            country_id: '',
            subject_id: '',
            level_id: ''

          })

          toast.success('Your request forwarded successfully..', {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          this.setState({ isLoading: false })
        }
      })
  }

  handleChangeLastName = event => {
    const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
    this.setState({ name: result })
  };

  handleChangeSubject = (e) => {
    console.log("subject_id Selected!!", e.target.value);
    this.setState({ subject_id: e.target.value });
    this.getLevel(e.target.value);
  }

  handleChangeLevel = (e) => {
    console.log("level_id Selected!!", e.target.value);
    this.setState({ level_id: e.target.value });
  }

  handleChangePhone = event => {
    const result = event.target.value.replace(/[^0-9]/gi, '');
    this.setState({ phone: result })
  };

  getLevel = async (value) => {

    await fetch(Constant.getLevels + '/' + value, {
      method: 'GET'
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({
            subLevelList: data.data,
            isLoading: false
          })
        }
      })
  }

  getSubject = async () => {

    await fetch(Constant.getSubject, {
      method: "GET"
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          this.setState({
            isLoading: false,
            SubjectList: data.data
          })
        } else {
          console.log('subject not available')
        }
      })

    this.getSubLevel();
  }

  getSubLevel = async () => {

    var subId = await localStorage.getItem('subjectId')

    await fetch(Constant.getSubjectLevels + '/' + subId, {
      method: 'GET'
    })
      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          console.log(data.data)
          this.setState({ LevelList: data.data })
        } else {
          console.log('data not found')
        }
      })
  }

  showDetails = (id, name) => {
    // console.log(id, name)
    const userId = localStorage.getItem('userId');

    // if (userId === null) {
    //   var currentUrlPath = window.location.pathname;
    //   var pathSegments = currentUrlPath.split('/').slice(1);
    //   var formattedPath = pathSegments.join('/');
    //   localStorage.setItem('currentPath', formattedPath)

    //   if (name === 'Competitive Exam') {
    //     window.location.href = Constant.BaseUrl + 'exam-preparation'
    //   } else {
    //     window.location.href = Constant.BaseUrl + 'login'
    //   }
    // }
    if (name === 'Competitive Exam') {
      window.location.href = Constant.BaseUrl + 'exam-preparation'
    } else {
      window.location.href = Constant.BaseUrl + 'show-details/' + id
    }

  }

  getLevels = async () => {
    const userId = localStorage.getItem('userId');
    const subjectId = localStorage.getItem('subjectId');

    fetch(Constant.getProfileLevel + '/' + userId + '/' + subjectId, {
      method: 'GET'
    })

      .then(Response => Response.json())
      .then(data => {
        if (data.success === true) {
          console.log(data)
          this.setState({
            selectedLevelId: data.data
          })
        }
      })
  }

  render() {
    const userId = localStorage.getItem('userId');
    const uniqueSubIds = new Set();
    return (
      <>

        <div className="row">
          <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
          <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 pt-5">
            <h1 className=" mt-5 head_font" >Online Math Tutors</h1>

            <p className="colorBlack mt-4 pt-3 pb-3 banner_sub_text" style={{ color: '#000', fontWeight: 600, fontSize: 18 }}>Explore the world of top-notch math education with Education Capsule. Our math tuition services are designed to accommodate learners of all abilities and age groups. We're committed to helping you master mathematics!</p>

            <div className="mt-4 ">
              <img alt="maths_banner" src={maths_banner} className="banner_radius" />
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
            <div className="learning-form " style={{ marginTop: 100 }} >
              <h2>Book Free Lesson Class</h2>
              <p>Learn From Best Online Tutors</p>
              <div className="slider-form-outer">
                <div className="form-group">
                  <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                  {!!this.state.nameError && (
                    <text className="colorBlack " style={{ marginLeft: "10px" }}>
                      {this.state.nameError}
                    </text>
                  )}

                  <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" minLength="10" maxLength="10" />
                  {!!this.state.phoneError && (
                    <text className="colorBlack " style={{ marginLeft: "10px" }}>
                      {this.state.phoneError}
                    </text>
                  )}

                  <input name="email" value={this.state.email} onChange={(event) =>
                    this.setState({ email: event.target.value })
                  } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                  {!!this.state.emailError && (
                    <text className="colorBlack " style={{ marginLeft: "10px" }}>
                      {this.state.emailError}
                    </text>
                  )}

                  <select name="subject_id" id="subject_id" onChange={this.handleChangeSubject} value={this.state.subject_id} className="form-control country_dropdown mt-1">
                    <option value="">Select Subject</option>
                    {
                      this.state.SubjectList.map((item, i) => (
                        <option key={i} value={item.id}>{item.title}</option>
                      ))
                    }

                  </select>
                  {!!this.state.subject_idError && (
                    <text style={{ color: "#000", marginLeft: "10px" }}>
                      {this.state.subject_idError}
                    </text>
                  )}

                  {
                    this.state.LevelList.length === 0 ? <select className="form-control mt-1"><option>Level Not Found</option></select> :
                      <select name="level_id" autoComplete="on" value={this.state.level_id} onChange={this.handleChangeLevel} className="form-control mt-1">
                        <option value="">Select Level</option>
                        {
                          this.state.subLevelList.map((item, i) => (
                            <option key={i} value={item.id}>{item.name}</option>
                          ))
                        }

                      </select>
                  }
                  {!!this.state.level_idError && (
                    <text style={{ color: "#000", marginLeft: "10px" }}>
                      {this.state.level_idError}
                    </text>
                  )}

                  <select name="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control country_dropdown mt-1">
                    <option value="">Select Country</option>
                    <option value="13" >Australia</option>
                    <option value="99" >India</option>
                    <option value="225" >United Kingdom</option>
                    <option value="226" >United States</option>
                  </select>
                  {!!this.state.country_idError && (
                    <text style={{ color: "#000", marginLeft: "10px" }}>
                      {this.state.country_idError}
                    </text>
                  )}


                  <div className="d-flex align-items-center ">
                    <ReCAPTCHA
                      sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                      size="invisible"
                      ref={this.recaptchaRef}
                    />
                  </div>
                  <div className="col-sm-6 ml-auto" >
                    <button
                      type="submit"
                      name="submit"
                      onClick={() => this.onSubmit()}
                      className="btnRegister enrolldemo"
                    >

                      {this.state.isLoading ? (
                        <ClipLoader
                          type="TailSpin"
                          color="#ffffff"
                          height="20"
                          width="20"
                        />
                      ) : (
                        "Book Demo Lessons"
                      )}

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
        </div>

        <ToastContainer />

        <div className=" mt-5 bg-black" style={{ border: 'none' }}>
          <div className="row">
            <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 pt-5">
              <div className="row">
                <div className="col-md-11 col-sm-12 col-lg-11 col-xl-11 col-xs-12">
                  <div className="reviwesfont1">5000+</div>
                  <div className="reviwesfont2">Student Enrolled</div>
                </div>
                <div className="col-md-1 mt-5 mobile_none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                    <path d="M1 0V63.4615" stroke="white" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 pt-5">
              <div className="row">
                <div className="col-md-11 text-center">
                  <img src={trust_pilot} alt="star" />
                </div>
                <div className="col-md-1 mt-5 mobile_none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                    <path d="M1 0V63.4615" stroke="white" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 text-center" style={{ padding: 50 }}>
              <img src={reviewgoogle} alt='reviews' />
            </div>

          </div>
        </div>

        <div className=" review_course">
          <p className="text-center mt-4 head_font" >Review courses</p>
          <div className="review_div bg-black mt-5">

            <div className="row  mb-5" style={{ display: 'flex', justifyContent: 'center' }}>
              {/* {
                userId === null ?
                  <> */}
              {
                this.state.LevelList.map((item, i) => (
                  <>
                    <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                    <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 mt-5">
                      <p className="key_font text-center">{item.name}</p>

                      <p className="mt-5 reviewcoursep" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>{item.description}</p>

                      <div className="mt-4 mb-4 mobile">
                        <a className="btn btn-info student-registration" href="javascript:void(0)" onClick={() => this.showDetails(item.id, item.name)} style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Review Courses</span></a>
                      </div>
                    </div>
                    <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                  </>
                ))
              }

              {/* </>
                  :
                  <>
                    {
                      this.state.selectedLevelId.map((item, i) => (
                        <>
                          <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                          <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 mt-5">
                            <p className="key_font text-center">{item.name}</p>

                            <p className="mt-5 reviewcoursep" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>{item.description}</p>

                            <div className="mt-4 mb-4 mobile">
                              <a className="btn btn-info student-registration" href="javascript:void(0)" onClick={() => this.showDetails(item.id, item.name)} style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Review Courses</span></a>
                            </div>
                          </div>
                          <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                        </>
                      ))
                    }

                  </>
              } */}

            </div>
          </div >

        </div >

        <div className="row">
          <p className="text-center mt-5 head_font">Why Education Capsule?</p>

          <p className="colorBlack mt-4 text-center" style={{ fontWeight: 500, fontSize: 20 }}>Do you want to lay a strong foundation for your children's maths skills? Or are you <br></br> simply
            looking for additional aid outside of the classroom? Make sure that <br></br>Education Capsule is
            your first choice! </p>
        </div>
        <div className="review_course row">
          <div className="col-md-3">
            <img src={why_edu1} alt="why Edu" className="why_edu1" />
          </div>
          <div className="col-md-6">
            <p className="text-center mt-4 text_res" style={{ fontWeight: 700, fontSize: 30, color: '#BD2D86' }}>One-to-One Online Maths Tuition</p>

            <p className="one_to_one mt-4">Our online maths tutoring is designed to be easy to follow and fun,
              keeping kids of all ages engaged in the subject and helping them to thrive when it
              comes to utilising their maths knowledge.<br></br><br></br>

              Our online platform allows us to help students across the UK at a time that is convenient,
              and our lessons are completely customised depending on the learner's needs. Whether
              you require primary education like Key Stage 1 and 2 or SATs preparations, or you're looking for Key Stage 3 or GCSE assistance, we're here to provide a private maths tutor
              to suit your needs.<br></br><br></br>

              Education Capsule's personalised and adaptive one-to-one online tutoring is trusted
              and recommended by thousands of parents and teachers across the UK. All of our
              learners are pre-assessed so that we can see their degree of maths understanding,
              focus on tricky spots, and create a unique learning plan to assist with learning - or to
              further develop number-related abilities.</p>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className=" review_course row">
          <div className="col-md-3"></div>
          <div className="col-md-6">

            <p className="text-center mt-4 text_res" style={{ fontWeight: 700, color: '#BD2D86' }}>The Perfect Maths Tutor For You!</p>

            <p className="one_to_one mt-4">Every child is different and can have their own unique needs that can impair their ability
              to effectively learn in the classroom, which can make finding the right maths tutor difficult, but our online lessons have been praised by parents across the country for
              being fun and engaging.<br></br><br></br>

              End your search here for professional and reliable private maths tutors. Every
              Mathematics tutor we have here at the Education Capsule also has expertise in other
              STEM subjects and has received all the necessary training and development to teach your children.<br></br><br></br>

              The tutor for each pupil remains the same throughout the sessions, allowing our tutors to
              get to know your child personally, and lessons are regularly observed to ensure
              curriculum and personal goals are being met.</p>
          </div>

          <div className="col-md-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img src={why_edu2} alt="why Edu" />
          </div>
        </div>
        <p className="text-center mt-5 text_res" style={{ fontWeight: 700, color: '#BD2D86' }}>Advance Your Child's Progress with an Online Maths Tutor</p>
        <div className="row mt-5">
          <div className="col-md-1 mt-3"></div>
          <div className="col-md-5 mt-3">
            <p className="colorBlack advance_child">Getting a little extra help outside of regular classes can do wonders for your child's
              advancement in their maths skills. In fact, our online maths classes and the homework
              we provide have been proven time and time again to help students reach their full
              potential. As an independent trail has shown, our online maths tutoring has seen
              students make 6 months worth of growth in less than 14 weeks - but academics aren't
              the only skills our students have developed.<br></br><br></br>

              As professional tutors, we want to bring out the best in our students, and we couldn't be
              more proud to say that we have the confidence boosts our learners get from
              one-on-one maths tuition online. This increase in confidence has led to better
              performance in exams and has allowed learners to further maths skills outside the
              classroom space.</p>
          </div>

          <div className="col-md-6 mt-3">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={advance_child} alt="advance Child" style={{ width: 500 }} />
            </div>
            <div className="mt-3 mobile">
              <NavLink className="btn btn-info student-registration" to="/book-a-tutor" style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Book free Lesson</span></NavLink>
            </div>
          </div>
        </div>

        <div className="review_course row">

          <div className="col-md-3">
            <img src={enganing} alt="Enganing" className="enganing" />
          </div>
          <div className="col-md-6 mt-5">
            <p className="text-center mt-4" style={{ fontWeight: 700, fontSize: 30, color: '#BD2D86' }}>Reliable and Engaging Tutors</p>

            <p className="one_to_one mt-4">All the educators and private maths tutors at Education Capsule are maths
              professionals and specialists who have completed the intensive initial tutor training
              successfully to impart online maths homework help by online maths tutor in a proactive
              manner.<br></br><br></br>

              Our online maths tutors put their best efforts to make learning fun for the learners. The
              lessons are assessed, and the quality is checked regularly, and private maths tutors
              obtain ongoing and enduring training and development. The online maths tuition at
              Education Capsule has assisted over 90,000 students to exceed their expectations and
              performance.</p>
          </div>
          <div className="col-md-3"></div>
        </div>

        <div className="review_course row">
          <div className="col-md-3"></div>
          <div className="col-md-6">

            <p className="text-center mt-4" style={{ fontWeight: 700, fontSize: 30, color: '#BD2D86' }}>Interactive Learning Space</p>

            <p className="one_to_one mt-4">Education Capsule provides lessons in a customised and interactive learning
              environment. Our platform is user-friendly and supports various educational activities -
              including preparing online curriculum, completing maths homework, and preparing for
              entrance exams. During online maths homework sessions, two-way communication
              takes place using editable screens, ensuring that learning is both enjoyable and
              effective.<br></br><br></br>

              Our online maths tutors bring complex concepts to life by incorporating interactive
              exercises, games, and visual aids. They also provide collaborative assistance with online
              homework and practice questions through annotation. As a result, the educators at
              Education Capsule take the time to understand each student individually, adjusting the
              delivery and pace of each lesson to enhance engagement and facilitate the child's
              progress.</p>
          </div>

          <div className="col-md-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <img src={interctive} alt="Interctive" />
          </div>
        </div>

        <div className=" bg-black mt-5">
          <p className="text-center pt-5" style={{ fontWeight: 700, fontSize: 38, color: '#fff' }}>Our Vision and Ambition</p>
          <div className="row mt-5" >
            <div className="col-md-2"></div>
            <div className="col-md-8 pb-5">
              <div className="row mt-4">
                <div className="col-md-3">
                  <img src={earth} alt="earth"/>
                </div>
                <div className="col-md-9">
                  <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 24, }}>Education Capsule</p>
                  <p className="mt-2 vision" style={{ textAlign: 'justify', color: '#fff', fontSize: 20 }}>is dedicated to supporting students worldwide with their maths-related needs and addressing any anxieties they may have. The current pandemic situation has hindered students from receiving the necessary guidance and
                    assistance to comprehend and complete their curriculum, particularly in maths.</p>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-3 mt-4">
                  <img src={breather} alt="breather"/>
                </div>
                <div className="col-md-9 mt-2">
                  <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 24, }}>Our goal</p>
                  <p className="mt-2 vision" style={{ textAlign: 'justify', color: '#fff', fontSize: 20 }}>at Education Capsule is to provide comprehensive assistance to students in
                    their maths lessons, both in regular school and for entrance exams. We understand that
                    each student holds a unique and valuable place, and we strive to help them excel and
                    enhance their skills through effective guidance and ongoing support, fostering their
                    career aspirations.</p>
                </div>
              </div>
              <div className="row mt-4 mb-5">
                <div className="col-md-3 mt-4">
                  <img src={sort} alt="breather"/>
                </div>
                <div className="col-md-9">
                  <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 24, }}>We offer</p>
                  <p className="mt-2 vision" style={{ textAlign: 'justify', color: '#fff', fontSize: 20 }}>regular online maths homework lessons facilitated by
                    qualified and committed tutors, as we believe they are essential to a child's
                    development. We firmly believe in making mathematical concepts enjoyable and
                    accessible for children through exciting interactive activities and games. With us, your
                    child will receive assistance with maths homework and over time, they will develop a
                    growing enthusiasm for maths, prompting them to seek more maths classes.</p>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>

          <p className="text-center pt-5" style={{ fontWeight: 700, fontSize: 38, color: '#fff' }}>Explore More</p>
          <div className="row pb-5">
            <div className="col-md-4 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div >
                <p className="pt-5" style={{ textAlign: 'justify', fontWeight: 700, fontSize: 39, color: '#fff' }}>Build your<br></br><br></br> Child’s Future </p>
                <p className="mt-4" style={{ textAlign: 'justify', color: '#fff', fontSize: 17 }}>Invest in their education<br></br> today for a tomorrow <br></br> filled with endless opportunities.</p>
              </div>
            </div>

            <div className="col-md-4  mt-5">
              {
                this.state.paperList.map((item, i) => (
                  <div key={i} className="mt-3">
                    <p className="text-center mt-4" onClick={() => this.showPaper(item.id, item.total_question)} style={{ color: '#fff', fontWeight: 600, fontSize: 20, cursor: 'pointer' }} >{item.name}</p>
                    <hr className="horizontal_line"></hr>
                  </div>
                ))
              }

              <div className="mt-3 mobile">
                <NavLink className="btn btn-info student-registration" to="/book-a-tutor" style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Book free Lesson</span></NavLink>
              </div>
            </div>
            <div className="col-md-4 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div>
                <img src={exploremore} alt="Exploremore" className="mt-4" style={{ width: 320 }} />
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}


export default Maths