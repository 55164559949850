import React from "react";
import { NavLink } from "react-router-dom";

import * as Constant from '../Constant'
import reviewgoogle from '../../assets/img/newhomepage/googlereview1.png'
import science_banner from '../../assets/img/subjects/science_banner.png'
import person from '../../assets/img/subjects/person.png'
import science_price from '../../assets/img/subjects/science_price.png'
import science_level from '../../assets/img/subjects/science_level.png'
import science_round from '../../assets/img/subjects/science_round.png'
import certified from '../../assets/img/subjects/certified.png'
import exploremore from '../../assets/img/subjects/exploremore.png'
import trust_pilot from '../../assets/img/trust_pilot.png'
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import ReCAPTCHA from 'react-google-recaptcha';

class Science extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoadingProfile: false,
            role_id: '',


            nameError: null,
            emailError: null,
            phoneError: null,
            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            captchError: null,
            num1: Math.floor(Math.random() * 10) + 1,
            num2: Math.floor(Math.random() * 10) + 1,
            answer: '',
            isCorret: '',

            SubjectList: [],
            subject_id: '',
            level_id: '',
            LevelList: [],
            level_idError: null,
            subject_idError: null,
            country_idError: null,
            country_id: '',
            moreSubjects: [],
            selectedLevelId: [],
            subLevelList: [],
            paperList: []
        }

        this.recaptchaRef = React.createRef();
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
    }

    componentDidMount = () => {
        this.getProfile();
        this.getSubject();
        this.getSubLevel();
        this.getPapers();
    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }

    getPapers = async () => {
        this.setState({ isLoading: true })

        var subjectId = localStorage.getItem('subjectId')

        await fetch(Constant.getPapers + '/' + subjectId, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                this.setState({ paperList: data.data, isLoading: false })
            })
    }

    showPaper = (id, totalQuestion) => {
        if (totalQuestion === 0) {
            const url = Constant.BaseUrl + 'contact-us'
            window.location.href = url;
        } else {
            const url = Constant.BaseUrl + 'exam_papers/1/' + id;
            // window.open(url, '_blank');
            window.location.href = url;
        }

    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let level_idError = '';
        let subject_idError = '';
        let country_idError = '';


        if (!this.state.name) {
            nameError = "Please Enter Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "The Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (this.state.level_id.length == 0) {
            level_idError = "Please select level"
        }

        if (this.state.subject_id.length == 0) {
            subject_idError = "Please select subject"
        }

        if (this.state.country_id.length == 0) {
            country_idError = "Please select country"
        }

        if (!this.state.phone) {
            phoneError = "Please Enter Contact Number"
        } else {
            if (this.state.phone.length <= 7) {
                phoneError = "Phone number cannot be less than eight digit"
            }
        }


        if (level_idError || subject_idError || nameError || emailError || phoneError || country_idError) {
            this.setState({
                level_idError,
                subject_idError,
                nameError,
                emailError,
                phoneError,
                country_idError
            });

            return false;
        }

        return true;
    }

    onEnrollDemo = async () => {
        this.setState({ isLoading: true })

        const recaptchaValue = await this.recaptchaRef.current.executeAsync();

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("country_id", this.state.country_id);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);
        formData.append("recaptchaToken", recaptchaValue);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        userInput: '',
                        country_id: '',
                        subject_id: '',
                        level_id: ''

                    })

                    toast.success('Your request forwarded successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };

    handleChangeSubject = (e) => {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel = (e) => {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
    }

    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };

    getLevel = async (value) => {

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        subLevelList: data.data,
                        isLoading: false
                    })
                }
            })
    }

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })

        this.getSubLevel();
    }

    getSubLevel = async () => {

        var subId = await localStorage.getItem('subjectId')

        await fetch(Constant.getSubjectLevels + '/' + subId, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    console.log(data.data)
                    this.setState({ LevelList: data.data })
                } else {
                    console.log('data not found')
                }
            })
    }

    getProfile = async () => {
        this.setState({ isLoadingProfile: true })

        const userId = localStorage.getItem('userId');

        await fetch(Constant.getUserProfile + '/' + userId, {
            method: "GET"
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        role_id: data.data.role_id,
                        isLoadingProfile: false,
                        subLevelId: data.data.sub_level_id,
                        moreSubjects: data.data.selectedCourses
                    })
                } else {
                    console.log('Not Found')
                }
            }
            )

        this.getLevels();
    }

    getLevels = async () => {
        const userId = localStorage.getItem('userId');
        const subjectId = localStorage.getItem('subjectId');

        fetch(Constant.getProfileLevel + '/' + userId + '/' + subjectId, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        selectedLevelId: data.data
                    })
                }
            })

    }

    showDetails = (id,name) => {
        const userId = localStorage.getItem('userId');

        // if (userId === null) {
        //     var currentUrlPath = window.location.pathname;
        //     var pathSegments = currentUrlPath.split('/').slice(1);
        //     var formattedPath = pathSegments.join('/');
        //     localStorage.setItem('currentPath', formattedPath)
        //     window.location.href = Constant.BaseUrl + 'login'
        // } else {
        //     window.location.href = Constant.BaseUrl + 'show-details/' + id
        // }


        if (name === 'Competitive Exam') {
            window.location.href = Constant.BaseUrl + 'exam-preparation'
          } else {
            window.location.href = Constant.BaseUrl + 'show-details/' + id
          }


    }
    render() {
        const userId = localStorage.getItem('userId');
        const uniqueSubIds = new Set();
        return (
            <>
                <div className="row">
                    <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
                    <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 pt-5">
                        <h1 className=" mt-5 head_font" >Online Science Tutors</h1>

                        <p className="colorBlack mt-4 pt-3 pb-3 banner_sub_text" style={{ color: '#000', fontWeight: 600, fontSize: 18 }}>Discover the boundless world of scientific knowledge with our tailored science programs. From foundational concepts to advanced topics, our experienced tutors are here to guide you at every level. Get in touch today for more information!</p>

                        <div className="mt-4 ">
                            <img alt="maths_banner" src={science_banner} className="banner_radius" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                        <div className="learning-form " style={{ marginTop: 100 }} >
                            <h2>Book Free Lesson Class</h2>
                            <p>Learn From Best Online Tutors</p>
                            <div className="slider-form-outer">
                                <div className="form-group">
                                    <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                                    {!!this.state.nameError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.nameError}
                                        </text>
                                    )}

                                    <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" minLength="10" maxLength="10" />
                                    {!!this.state.phoneError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.phoneError}
                                        </text>
                                    )}

                                    <input name="email" value={this.state.email} onChange={(event) =>
                                        this.setState({ email: event.target.value })
                                    } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                                    {!!this.state.emailError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.emailError}
                                        </text>
                                    )}

                                    <select name="subject_id" id="subject_id" onChange={this.handleChangeSubject} value={this.state.subject_id} className="form-control country_dropdown mt-1">
                                        <option value="">Select Subject</option>
                                        {
                                            this.state.SubjectList.map((item, i) => (
                                                <option key={i} value={item.id}>{item.title}</option>
                                            ))
                                        }

                                    </select>
                                    {!!this.state.subject_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.subject_idError}
                                        </text>
                                    )}

                                    {
                                        this.state.LevelList.length === 0 ? <select className="form-control mt-1"><option>Level Not Found</option></select> :
                                            <select name="level_id" autoComplete="on" value={this.state.level_id} onChange={this.handleChangeLevel} className="form-control mt-1">
                                                <option value="">Select Level</option>
                                                {
                                                    this.state.subLevelList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    ))
                                                }

                                            </select>
                                    }
                                    {!!this.state.level_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.level_idError}
                                        </text>
                                    )}

                                    <select name="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control country_dropdown mt-1">
                                        <option value="">Select Country</option>
                                        <option value="13" >Australia</option>
                                        <option value="99" >India</option>
                                        <option value="225" >United Kingdom</option>
                                        <option value="226" >United States</option>
                                    </select>
                                    {!!this.state.country_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.country_idError}
                                        </text>
                                    )}


                                    <div className="d-flex align-items-center ">
                                        <ReCAPTCHA
                                            sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                            size="invisible"
                                            ref={this.recaptchaRef}
                                        />
                                    </div>
                                    <div className="col-sm-6 ml-auto" >
                                        <button
                                            type="submit"
                                            name="submit"
                                            onClick={() => this.onSubmit()}
                                            className="btnRegister enrolldemo"
                                        >

                                            {this.state.isLoading ? (
                                                <ClipLoader
                                                    type="TailSpin"
                                                    color="#ffffff"
                                                    height="20"
                                                    width="20"
                                                />
                                            ) : (
                                                "Book Demo Lessons"
                                            )}

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
                </div>
                <ToastContainer />

                <div className="mt-5 bg-black" style={{ border: 'none' }}>
                    <div className="row">
                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11">
                                    <div className="reviwesfont1">5000+</div>
                                    <div className="reviwesfont2">Student Enrolled</div>
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11 text-center">
                                    <img src={trust_pilot} alt="star" />
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 text-center" style={{ padding: 50 }}>
                            <img src={reviewgoogle} alt='reviews' />
                        </div>

                    </div>
                </div>

                <div className="new_bg">
                    <div className="row mt-5">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 bg-black" style={{ borderRadius: 50 }}>
                            <div className="row pb-5" >
                                <p className="text-center mt-5 " style={{ fontWeight: 400, fontSize: 35, color: '#fff' }}>Get One to One Online Science Tutor</p>

                                <div className="col-md-2"> </div>
                                <div className="col-md-8 mb-5">
                                    <p className=" mt-5 " style={{ fontWeight: 400, fontSize: 18, color: '#fff', textAlign: 'justify' }}>For every student, the inner fear of asking questions of a teacher prevails at the very early stages. Some students overcome this fear early, promptly ask questions to teachers, clarify their doubts in the class itself, and excel in their academic achievement. However, most students are unable to get rid of the shyness and fear that make them remain in subjective doubt for a long time. This severely hampers their academic performance in the primary stages and continues to drop dramatically in the secondary and upper grades.
                                        Education Capsule helps lay a strong foundation in Science through one-to-one personalised sessions and helps students understand concepts in an easy and fun way. Science is one of the basic curriculum disciplines that employers and future educational institutions will use as part of their skills evaluation, along with English and Math.
                                    </p>

                                </div>
                                <div className="col-md-2">
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <div className="review_div bg-black mt-5">
                        <div className="row  mb-5" style={{ display: 'flex', justifyContent: 'center' }}>
                            {/* {
                                userId === null ?
                                    <> */}
                            {
                                this.state.LevelList.map((item, i) => (
                                    <>
                                        <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                                        <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 mt-5">
                                            <p className="key_font text-center">{item.name}</p>

                                            <p className="mt-5 reviewcoursep" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>{item.description}</p>

                                            <div className="mt-4 mb-4 mobile">
                                                <a className="btn btn-info student-registration" href="javascript:void(0)" onClick={() => this.showDetails(item.id, item.name)} style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Review Courses</span></a>
                                            </div>
                                        </div>
                                        <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                                    </>
                                ))
                            }

                            {/* </>
                                    :
                                    <>
                                        {
                                            this.state.selectedLevelId.map((item, i) => (
                                                <>
                                                    <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                                                    <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xs-12 mt-5">
                                                        <p className="key_font text-center">{item.levelname}</p>

                                                        <p className="mt-5 reviewcoursep" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>{item.description}</p>

                                                        <div className="mt-4 mb-4 mobile">
                                                            <a className="btn btn-info student-registration" href="javascript:void(0)" onClick={() => this.showDetails(item.level_id, item.levelname)} style={{ backgroundColor: '#a32e90', color: '#fff', borderRadius: 30 }}><span>Review Courses</span></a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1 col-sm-12 col-lg-1 col-xl-1 col-xs-12"></div>
                                                </>
                                            ))
                                        }

                                    </>
                            } */}

                        </div>
                    </div>

                    <div className="row mt-5 ">
                        <div className="col-md-4 sciencemb" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="career_div" style={{ height: 'auto', padding: 35 }}>
                                <p className="career_text center" >Our Vision</p>

                                <p className="career_font mt-3 " style={{ fontSize: 20 }}>Our Vision at Education Capsule is to provide extended support and help students learn Science concepts, enhance their science knowledge, and apply science in their daily lives in the best manner. </p>
                            </div>
                        </div>
                        <div className="col-md-4 sciencemb" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="career_div" style={{ height: 'auto', padding: 35 }}>
                                <p className="career_text center">Our Goal</p>

                                <p className="career_font mt-3" style={{ fontSize: 20 }}>Our goal is to ensure that all students at Education Capsule can understand science concepts effectively and can score higher grades in the subject. Also, our goal is to ensure overall practical knowledge enhancement of students in the subject. </p>
                            </div>

                        </div>
                        <div className="col-md-4 sciencemb" style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className="career_div" style={{ height: 'auto', padding: 35 }}>
                                <p className="career_text center ">We offer</p>

                                <p className="career_font mt-3" style={{ fontSize: 20 }}>At Education Capsule, we provide personalised online science tutoring sessions to students at all levels indicating Key Stage 1, Key Stage 2, Key Stage 3, and GCSE programs. Other than this, we also help students to prepare for Competitive exams.</p>
                            </div>

                        </div>
                    </div>

                    <p className="text-center mt-5 head_font">Why Education Capsule?</p>
                    <div className=" mt-5">
                        <div className="row" >
                            <div className="col-md-2"></div>
                            <div className="col-md-8 pb-5 bg-black" style={{ borderRadius: 50 }}>
                                <div className="row mt-4">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={person} alt="person" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 22 }}>Personalised For Each Child</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>Our Science classes are tailor-made as per the requirements of each child so that our tutors can keep up with the learning pace of students and can provide them with their best online science tutoring services as per their unique needs. Science teachers for each student remain the same throughout the session. </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr className="horizontal_line line_width"></hr>
                                <div className="row mt-4">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={certified} alt="certified" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 24, }}>Dedicated Science Teachers</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>Our Science tutors are well-trained and qualified and provide their services around the clock for students’ convenience. Students can select their teacher by accessing our website and checking their educational qualifications and experiences.
                                        </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr className="horizontal_line line_width"></hr>
                                <div className="row mt-2">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={science_round} alt="clock" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 24, }}>Learn Anytime, Anywhere</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>Our Science classes are available online so that students can access our classes and notes anytime and anywhere. Students are just required to check our website, book their classes on a particular topic at their specified time, and select a particular science teacher from whom they want to study the lessons.  </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr className="horizontal_line line_width"></hr>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={science_level} alt="Level" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 24, }}>Interactive learning scape</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>Our tutors help students to excel in their subjects by creating an interactive learning environment for them. Interactive exercises like role play and real-life examples are used to make the subject interesting for kids. Students are also engaged through fun quizzes and puzzles. </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                                <hr className="horizontal_line line_width"></hr>
                                <div className="row mt-4 mb-5">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 imagestyle">
                                        <img src={science_price} alt="Price" style={{ width: 105 }} />
                                    </div>
                                    <div className="col-md-8">
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 24, }}>Use of Customer-Made Resources</p>
                                        <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 18 }}>We provide specialised and specific customer-made services to help students develop their learning about the subject. We at Education Capsule provide full freedom to students to select a particular science topic they want to study and get online coaching. </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </div>



                <div className="bg-black mt-5" style={{ border: 'none' }}>
                    <div className="row pb-5">
                        <div className="col-md-6 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div >
                                <p className="pt-5" style={{ textAlign: 'justify', fontWeight: 700, fontSize: 39, color: '#fff' }}>Build your<br></br><br></br> Child’s Future </p>
                                <p className="mt-4" style={{ textAlign: 'justify', color: '#fff', fontSize: 17 }}>Education Capsule works on<br></br> developing the critical thinking<br></br> skills of students and thus<br></br> building a strong foundation<br></br> for their future.</p>
                            </div>
                        </div>
                        <div className="col-md-6 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div>
                                <img src={exploremore} alt="Exploremore" className="mt-4" style={{ width: 320 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}


export default Science