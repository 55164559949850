import React from "react";
import { NavLink } from "react-router-dom";

import * as Constant from '../Constant'
import reviewgoogle from '../../assets/img/newhomepage/googlereview1.png'
import computing_banner from '../../assets/img/subjects/computing_banner.png'
import ClipLoader from "react-spinners/ClipLoader";
import { ToastContainer, toast } from "react-toastify";
import earth from '../../assets/img/subjects/earth.png'
import breather from '../../assets/img/subjects/breather.png'
import sort from '../../assets/img/subjects/sort.png'
import exploremore from '../../assets/img/subjects/exploremore.png'
import trust_pilot from '../../assets/img/trust_pilot.png'
import ReCAPTCHA from 'react-google-recaptcha';

class Computing extends React.Component {

    constructor() {
        super()

        this.state = {
            isLoadingProfile: false,
            role_id: '',


            nameError: null,
            emailError: null,
            phoneError: null,
            captchaText: '',
            userInput: '',
            isCaptchaValid: false,
            captchError: null,
            num1: Math.floor(Math.random() * 10) + 1,
            num2: Math.floor(Math.random() * 10) + 1,
            answer: '',
            isCorret: '',

            SubjectList: [],
            subject_id: '',
            level_id: '',
            LevelList: [],
            level_idError: null,
            subject_idError: null,
            country_idError: null,
            country_id: '',
            paperList: []
        }

        this.recaptchaRef = React.createRef();
        this.handleChangeSubject = this.handleChangeSubject.bind(this);
        this.handleChangeLevel = this.handleChangeLevel.bind(this);
    }

    componentDidMount = () => {
        this.getProfile();
        this.getSubject();
        this.getPapers()
    }

    onSubmit = () => {
        if (this.validate()) {
            this.setState({
                nameError: null,
                emailError: null,
                phoneError: null,
            })
            this.onEnrollDemo();
        }
    }

    getPapers = async () => {
        this.setState({ isLoading: true })

        var subjectId = localStorage.getItem('subjectId')

        await fetch(Constant.getPapers + '/' + subjectId, {
            method: 'GET'
        })

            .then(Response => Response.json())
            .then(data => {
                this.setState({ paperList: data.data, isLoading: false })
            })
    }

    showPaper = (id, totalQuestion) => {
        if (totalQuestion === 0) {
            const url = Constant.BaseUrl + 'contact-us'
            window.location.href = url;
        } else {
            const url = Constant.BaseUrl + 'exam_papers/1/' + id;
            // window.open(url, '_blank');
            window.location.href = url;
        }

    }

    validate = () => {
        let nameError = '';
        let emailError = '';
        let phoneError = '';
        let level_idError = '';
        let subject_idError = '';
        let country_idError = '';


        if (!this.state.name) {
            nameError = "Please Enter Name"
        }

        var reg = new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}");
        if (!this.state.email) {
            emailError = "The Email Address cannot be empty.";
        } else {
            if (!reg.test(this.state.email)) {
                emailError = "Please enter a valid Email Address.";
            }
        }

        if (this.state.level_id.length == 0) {
            level_idError = "Please select level"
        }

        if (this.state.subject_id.length == 0) {
            subject_idError = "Please select subject"
        }

        if (this.state.country_id.length == 0) {
            country_idError = "Please select country"
        }

        if (!this.state.phone) {
            phoneError = "Please Enter Contact Number"
        } else {
            if (this.state.phone.length <= 7) {
                phoneError = "Phone number cannot be less than eight digit"
            }
        }


        if (level_idError || subject_idError || nameError || emailError || phoneError || country_idError) {
            this.setState({
                level_idError,
                subject_idError,
                nameError,
                emailError,
                phoneError,
                country_idError
            });

            return false;
        }

        return true;
    }

    onEnrollDemo = async () => {
        this.setState({ isLoading: true })
        const recaptchaValue = await this.recaptchaRef.current.executeAsync();

        const formData = new FormData();
        formData.append("name", this.state.name);
        formData.append("email", this.state.email);
        formData.append("phone", this.state.phone);
        formData.append("country_id", this.state.country_id);
        formData.append("subject_id", this.state.subject_id);
        formData.append("level_id", this.state.level_id);
        formData.append("recaptchaToken", recaptchaValue);

        await fetch(Constant.FreeDemo, {
            method: 'POST',
            body: formData,
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({ isLoading: false })
                    this.setState({
                        name: '',
                        email: '',
                        phone: '',
                        userInput: '',
                        country_id: '',
                        subject_id: '',
                        level_id: ''

                    })

                    toast.success('Your request forwarded successfully..', {
                        position: 'top-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    this.setState({ isLoading: false })
                }
            })
    }

    handleChangeLastName = event => {
        const result = event.target.value.replace(/[^a-zA-Z, ]/, '');
        this.setState({ name: result })
    };

    handleChangeSubject = (e) => {
        console.log("subject_id Selected!!", e.target.value);
        this.setState({ subject_id: e.target.value });
        this.getLevel(e.target.value);
    }

    handleChangeLevel = (e) => {
        console.log("level_id Selected!!", e.target.value);
        this.setState({ level_id: e.target.value });
    }

    handleChangePhone = event => {
        const result = event.target.value.replace(/[^0-9]/gi, '');
        this.setState({ phone: result })
    };

    getSubject = async () => {

        await fetch(Constant.getSubject, {
            method: "GET"
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        isLoading: false,
                        SubjectList: data.data
                    })
                } else {
                    console.log('subject not available')
                }
            })
    }

    getLevel = async (value) => {

        await fetch(Constant.getLevels + '/' + value, {
            method: 'GET'
        })
            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        LevelList: data.data,
                        isLoading: false
                    })
                }
            })
    }

    getProfile = async () => {
        this.setState({ isLoadingProfile: true })

        const userId = localStorage.getItem('userId');

        await fetch(Constant.getUserProfile + '/' + userId, {
            method: "GET"
        })

            .then(Response => Response.json())
            .then(data => {
                if (data.success === true) {
                    this.setState({
                        role_id: data.data.role_id,
                    })
                } else {
                    console.log('Not Found')
                }
            }
            )
    }
    render() {
        return (
            <>

                <div className="row">
                    <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
                    <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12 pt-5">
                        <p className=" mt-5 head_font" >Online Computing Tutors</p>

                        <p className="colorBlack mt-4 pt-3 pb-3 banner_sub_text" style={{ color: '#000', fontWeight: 600, fontSize: 18 }}>Education Capsule offers specialized computing programs for students of all levels, from coding basics to advanced technology. Our experienced instructors are dedicated to helping you succeed in the digital age. Enroll today and start your computing adventure!</p>

                        <div className="mt-4 ">
                            <img alt="computing_banner" src={computing_banner} className="banner_radius" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xs-12">
                        <div className="learning-form " style={{ marginTop: 100 }} >
                            <h2>Book Free Lesson Class</h2>
                            <p>Learn From Best Online Tutors</p>
                            <div className="slider-form-outer">
                                <div className="form-group">
                                    <input name="name" autoComplete="on" value={this.state.name} onChange={this.handleChangeLastName} type="text" className="form-control" placeholder="Enter Your Name" id="name" />
                                    {!!this.state.nameError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.nameError}
                                        </text>
                                    )}

                                    <input name="phone" autoComplete="on" value={this.state.phone} onChange={this.handleChangePhone} type="text" className="form-control" placeholder="Enter Your Phone Number" id="phone" minLength="10" maxLength="10" />
                                    {!!this.state.phoneError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.phoneError}
                                        </text>
                                    )}

                                    <input name="email" value={this.state.email} onChange={(event) =>
                                        this.setState({ email: event.target.value })
                                    } type="email" autoComplete="on" className="form-control" placeholder="Email Address" id="email" />
                                    {!!this.state.emailError && (
                                        <text className="colorBlack " style={{ marginLeft: "10px" }}>
                                            {this.state.emailError}
                                        </text>
                                    )}

                                    <select name="subject_id" id="subject_id" onChange={this.handleChangeSubject} value={this.state.subject_id} className="form-control country_dropdown mt-1">
                                        <option value="">Select Subject</option>
                                        {
                                            this.state.SubjectList.map((item, i) => (
                                                <option key={i} value={item.id}>{item.title}</option>
                                            ))
                                        }

                                    </select>
                                    {!!this.state.subject_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.subject_idError}
                                        </text>
                                    )}

                                    {
                                        this.state.LevelList.length === 0 ? <select className="form-control mt-1"><option>Level Not Found</option></select> :
                                            <select name="level_id" autoComplete="on" value={this.state.level_id} onChange={this.handleChangeLevel} className="form-control mt-1">
                                                <option value="">Select Level</option>
                                                {
                                                    this.state.LevelList.map((item, i) => (
                                                        <option key={i} value={item.id}>{item.name}</option>
                                                    ))
                                                }

                                            </select>
                                    }
                                    {!!this.state.level_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.level_idError}
                                        </text>
                                    )}

                                    <select name="country_id" value={this.state.country_id} onChange={(event) => this.setState({ country_id: event.target.value })} className="form-control country_dropdown mt-1">
                                        <option value="">Select Country</option>
                                        <option value="13" >Australia</option>
                                        <option value="99" >India</option>
                                        <option value="225" >United Kingdom</option>
                                        <option value="226" >United States</option>
                                    </select>
                                    {!!this.state.country_idError && (
                                        <text style={{ color: "#000", marginLeft: "10px" }}>
                                            {this.state.country_idError}
                                        </text>
                                    )}


                                    <div className="d-flex align-items-center ">
                                        <ReCAPTCHA
                                            sitekey="6Lea4DQpAAAAANJrP1sF9UemInf1guaRrI0I60nf"
                                            size="invisible"
                                            ref={this.recaptchaRef}
                                        />
                                    </div>
                                    <div className="col-sm-6 ml-auto" >
                                        <button
                                            type="submit"
                                            name="submit"
                                            onClick={() => this.onSubmit()}
                                            className="btnRegister enrolldemo"
                                        >

                                            {this.state.isLoading ? (
                                                <ClipLoader
                                                    type="TailSpin"
                                                    color="#ffffff"
                                                    height="20"
                                                    width="20"
                                                />
                                            ) : (
                                                "Book Demo Lessons"
                                            )}

                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2 col-xs-12"></div>
                </div>

                <ToastContainer />

                <div className="mt-5 bg-black" style={{ border: 'none' }}>
                    <div className="row">
                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11">
                                    <div className="reviwesfont1">5000+</div>
                                    <div className="reviwesfont2">Student Enrolled</div>
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 pt-5">
                            <div className="row">
                                <div className="col-md-11 text-center">
                                    <img src={trust_pilot} alt="star" />
                                </div>
                                <div className="col-md-1 mt-5 mobile_none">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="64" viewBox="0 0 2 64" >
                                        <path d="M1 0V63.4615" stroke="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 text-center" style={{ padding: 50 }}>
                            <img src={reviewgoogle} alt='reviews' />
                        </div>

                    </div>
                </div>
                <div className="new_bg">
                    <div className="row mt-5 ">
                        <div className="col-md-2"></div>
                        <div className="col-md-8 bg-black" style={{ borderRadius: 50 }}>
                            <div className="row pb-5" >
                                <p className="text-center mt-5 " style={{ fontWeight: 400, fontSize: 35, color: '#fff' }}>Get One to One Online IT Tutor</p>

                                <div className="col-md-2"> </div>
                                <div className="col-md-8 mb-5">
                                    <p className=" mt-5 " style={{ fontWeight: 400, fontSize: 18, color: '#fff', textAlign: 'justify' }}>Computing is an important part of the UK National Curriculum. Children who learn to code and who learn about computer science can become independent adults in a technology-rich society. Learning to code helps students better understand an aspect of the digital world we live in. The goal of computing is no longer just to become an expert programmer and meet a need in the job market. It allows children to progress in all aspects of life.<br></br><br></br>

                                        At Education Capsule, we believe that, if provided with the right help, any student can excel in their studies. We do believe that every learner is special and worthy of learning at their own pace. Therefore, we created a platform that can help every learner reach their full potential: Education Capsule. Education Capsule is an online tutoring platform with qualified tutors. We provide one-to-one and group teaching services using our handcrafted online teaching platform.</p>

                                </div>
                                <div className="col-md-2">
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                </div>

                <div className="container">
                    <div className="row mt-4 ">
                        <div className="col-md-12 col-sm-12 section_4">
                            <p className="sec4headtext" style={{ marginTop: -12 }}>Programming</p>
                            <p className="colorBlack section_4text" >Education Capsule helps students build their fundamentals of programming with the aid of our well-qualified and trained IT tutors. Our tutors teach computer programs to students and help them understand and write basic and useful programs with the help of different programming languages like Python.</p>

                            <p className="sec4headtext " style={{ textAlign: 'end', marginRight: 80 }}>Internet safety</p>
                            <p className="colorBlack section_4text" style={{ marginTop: 130, marginBottom: 133 }} >Our online IT tutors teach students about internet safety and make them aware of various online dangers. The basic understanding of internet safety is discussed during the online sessions with the kids.</p>

                            <p className="sec4headtext" >Multimedia</p>
                            <p className="colorBlack section_4text" style={{ marginTop: 103 }} >Learning Multimedia could be very useful for Kids in their further studies and work. Like, learning to present ideas through PowerPoint or approaching pre-recorded teachings comes under Multimedia learning. Our IT tutors help students throughout their sessions in learning multimedia and their various uses.</p>

                            <p className="sec4headtext" style={{ textAlign: 'end', marginRight: 105, paddingTop: 10 }}>Technology in our lives</p>
                            <p className="colorBlack section_4text" style={{ marginTop: 120, marginBottom: 137 }} >Technology is used in our everyday lives mainly for exchanging information, driving cars, cleaning clothes etc. Thus, learning basic technological terms and how they influence our day-to-day operations is essential and at Education Capsule it is possible now.</p>

                        </div>
                    </div>
                </div>


                <div className="bg-black mt-5">
                    <p className="text-center pt-5" style={{ fontWeight: 700, fontSize: 38, color: '#fff' }}>Our Vision and Ambition</p>
                    <div className="row" style={{ marginLeft: '15%', marginRight: '15%' }}>
                        <div className="col-md-2"></div>
                        <div className="col-md-8 pb-5">
                            <div className="row mt-4">
                                <div className="col-md-3">
                                    <img src={earth} alt="earth"/>
                                </div>
                                <div className="col-md-9">
                                    <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 23, }}>Our Mission</p>
                                    <p className="mt-3 vision block_font" style={{ color: '#fff' }}>Educatino Capsule is dedicated to providing appropriate support to the students for learning computer science and its related concepts such as basics of programming, internet safety, technology and multimedia.</p>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-3 mt-3">
                                    <img src={breather} alt="breather"/>
                                </div>
                                <div className="col-md-9 mt-3">
                                    <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 23, }}>Our goal</p>
                                    <p className="mt-3 vision block_font" style={{ color: '#fff' }}>is to provide our best assistance to computer science students through our well-trained IT tutors. We work hard to ensure that our students get ongoing support throughout their sessions to learn their computing sessions in the best manner.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-4 mb-5">
                                <div className="col-md-3 mt-4">
                                    <img src={sort} alt="sort"/>
                                </div>
                                <div className="col-md-9 mt-3">
                                    <p className="mt-3" style={{ textAlign: 'justify', color: '#fff', fontSize: 23, }}>We offer</p>
                                    <p className="mt-3 vision block_font" style={{ color: '#fff', }}>At Education Capsule we offer the best and most affordable online computing learning sessions for kids for all levels from Key Stage 1 to GCSE. We also help students to prepare for Computing competitive exams for all stages.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>

                    <div className="row pb-5">
                        <div className="col-md-6 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div >
                                <p className="pt-5" style={{ textAlign: 'justify', fontWeight: 700, fontSize: 39, color: '#fff' }}>Build your<br></br><br></br> Child’s Future </p>
                                <p className="mt-4" style={{ textAlign: 'justify', color: '#fff', fontSize: 17, width: 300 }}>Provide every possibility to make your child excel in their studies and brighten their future with us. We at Education Capsule provide the best online tutoring services to ensure the best for your child’s future.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 pt-5" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <div>
                                <img src={exploremore} alt="Exploremore" className="mt-4" style={{ width: 320 }} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Computing