import React from "react";
import sen from '../../assets/img/services/new/sen.png'


class SpecialEducation extends React.Component {

    render() {
        return (
            <>
                <div style={{ marginTop: '-1px' }}>
                    <section className="service-page-new" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7 col-sm-12 col-lg-7 col-xl-7 col-xs-12">
                                    <h1 className="banner-text1" style={{ textAlign: 'left', marginTop: 55 }}>Every Student is Special!</h1>
                                    <p style={{ color: '#fff', textAlign: 'justify', fontSize: 19 }}>We provide specialized teaching services for the learners seeking additional support and struggle with ADHD, Dyscalculia, intellectual, vision, hearing and speech disabilities etc.
                                    </p>
                                    <a href="/book-a-tutor" className="lending-btn" >Book free Lesson Now</a>

                                </div>
                                <div className="col-md-5 col-sm-12 col-lg-5 col-xl-5 col-xs-12">
                                    <img src={sen} alt="sen" className="p-2" />
                                </div>

                            </div>
                        </div>
                    </section >

                    <section style={{ marginTop: '-265px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="cariculam-help-section">
                                        <h2>Special Education Needs</h2>
                                        <p className="subheading" >At Education Capsule, we believe that every learner is special and worthy of learning at their own pace.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-12 col-md-6">
                                    <div className="subheading">
                                        <p className="block_font" style={{ textAlign: 'justify' }}>At Education Capsule, we believe that every learner is special and worthy of learning at their own pace. Special Education Needs (SEN) is the lawful term to characterize the needs of a student’s disability which makes learning harder for them than for different kids their age and requires the specialized provision of education to be made for that student. In Education Capsule, the tutors are trained in such a way that they can support a kid with special needs as per their unique requirements of learning. The tutors make every possible effort to make the learning process easy and enjoyable for them. We provide specialized teaching services for learners seeking additional support and struggling with ADHD,Dyscalculia,intellectual,vision,hearing and speech disabilities, etc. Our main focus is to offer teaching courses designed to fabricate the child’s development process by expert and qualified tutors who have previous experience in dealing with children with special needs and disabilities. We will make an effort to match your child with a special and permanent tutor who will help in filling the missing gaps in pupils’ knowledge and equip them with the skills required to become independent learners. Our tutors will help special needs learners by boosting their confidence by enabling them to progress in a safe and comfortable environment. Also, working with our skilled tutors would help learners in learning new skills to remain focused by obtaining the one-on-one attention required for efficiently supplementing the current curriculum. We are an online platform allowing fair and equal access to education to all pupils across the globe aligned with the school curriculum to enhance learners’ needs, strengths, and goals.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container mt-4" >
                            <h2 style={{ color: '#000', textAlign: 'center' }}>SEN Students we have served </h2>
                            <p className="subheading" >Education Capsule has served various students with the following needs</p>

                            <div className="row justify-content-evenly">
                                <div className="col-lg-3 col-12 col-md-4">
                                    <div className="special-text-new"><a href="#" style={{ color: '#7EC8E3' }}> ADHD</a></div>
                                </div>
                                <div className="col-lg-3 col-12 col-md-4">
                                    <div className="special-text-new"><a href="#" style={{ color: '#F44E22' }}> Dyscalculia</a></div>
                                </div>
                                <div className="col-lg-3 col-12 col-md-4">
                                    <div className="special-text-new"><a href="#" style={{ color: '#8B3DFF' }}> Dyslexia</a></div>
                                </div>
                            </div>

                            <div className="row justify-content-evenly">
                                <div className="col-lg-3 col-12 col-md-6">
                                    <div className="special-text-new"><a href="#" style={{ color: '#7EC8E3' }}>Autism</a></div>
                                </div>
                                <div className="col-lg-3 col-12 col-md-6">
                                    <div className="special-text-new"><a href="#" style={{ color: '#F44E22' }}>SEMH</a></div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </>

        )
    }
}

export default SpecialEducation