import React from "react";
import { Navigate } from "react-router-dom";


class Wallet extends React.Component {

    render() {
        var userId = localStorage.getItem("userId");
        if (userId) {
            return (
                <>
                    <section class="atf-section-padding" style={{ minHeight: '1080px' }}>
                        <div class="container">
                            <div class="tab-content">
                                <div class="row">
                                    {/* <button class="btnRegister" type="submit" style={{ width: '150px', marginTop: '0px', marginLeft: '20px' }} readonly>Add More Coins</button> */}
                                    <div class="col-lg-12 col-xl-12">
                                        <div class="tabbing-content-outer" style={{ marginTop: '70px' }}>
                                            <div class="tab-content">
                                                <div class="tab-pane active" role="tabpanel">
                                                    <h1 class="step_title">Total Coins You Have</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
        } else {
            return <Navigate to="/" />;
        }

    }
}

export default Wallet